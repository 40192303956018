import React from 'react';
import './TeamPlayersTab.css';
import { useNavigate } from 'react-router-dom';
import starCaptainImg from '../../../../assets/img/LobbyIndicators/starСaptain.svg';
import { ICommonPlayerModel } from '../../../../models/dto/player/ICommonPlayerModel';


interface TeamPlayersProps {
    players: ICommonPlayerModel[];
    captainId: string | null;
}


const TeamPlayersTab: React.FC<TeamPlayersProps> = ({ players, captainId }) => {
    const navigate = useNavigate(); 
    
    let sortPlayers = players.sort((a, b) => {
        if (a.playerId === captainId && b.playerId !== captainId) {
            return -1;
        }
        
        if (a.playerId !== captainId && b.playerId === captainId) {
            return 1;
        }
        
        return Number(a.playerId) - Number(b.playerId);
    });
    
    return (
        <div className='teamPlayersTab_container'>
            {
                sortPlayers.map((player, index) => {
                    let steamProfileUri: string = `${process.env.REACT_APP_STEAM_PROFILE_URL}${player?.steamID}`;
                    
                    return (
                        <div className='teamPlayers_player_mainContainer' key={player.playerId}>
                            <hr className='teamPlayers_line'></hr>
                            
                            <div key={index} className='teamPlayers_player_container'>
                                <div className='teamPlayers_player_logo_container'>
                                    <div className='teamPlayers_player_logo' style={{backgroundImage: `url(${process.env.REACT_APP_IMG_STORAGE_URL}${player.avatarURL})`}}></div>
                                </div>
                                <div className='teamPlayers_players_container'>
                                    <div className='teamPlayers_player_link'>
                                        <h3 className={`teamPlayers_player_titles ${player.login.length >= 18 ? 'big' : ''}`} onClick={() => { navigate(`/player/${player.playerId}`)}}>{player.login}</h3>
                                        {player.playerId === captainId && <img alt='captain' className='teamCaptainImg' src={starCaptainImg} />}
                                    </div>
                                </div>
                            </div>
                            
                            <hr className='teamPlayers_line'></hr>
                        </div>
                    )
                })
            }
        </div>
    );
};

export default TeamPlayersTab;