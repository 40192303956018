import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { FiRefreshCw, FiStopCircle, FiCheckCircle, FiUnlock } from 'react-icons/fi';
import './DOTA2BotControlViget.css';
interface DOTA2BotControlVigetProps {
    onRestartBot: () => Promise<void>;
    onStopBot: () => Promise<void>;
    onCheckStatus: () => Promise<void>;
}

const DOTA2BotControlViget: React.FC<DOTA2BotControlVigetProps> = ({
    onRestartBot,
    onStopBot, 
    onCheckStatus
}) => {
    const [showConfirmStop, setShowConfirmStop] = useState(false);
    const [loading, setLoading] = useState({
        restart: false,
        stop: false,
        check: false
    });

    const handleRestartBot = async () => {
        setLoading(prev => ({ ...prev, restart: true }));
        try {
            await onRestartBot();
        } catch (error) {
            console.error('Ошибка при перезапуске бота:', error);
            alert('Произошла ошибка при перезапуске бота.');
        } finally {
            setLoading(prev => ({ ...prev, restart: false }));
        }
    };

    const handleStopBot = async () => {
        setShowConfirmStop(false);
        setLoading(prev => ({ ...prev, stop: true }));
        try {
            await onStopBot();
        } catch (error) {
            console.error('Ошибка при остановке бота:', error);
            alert('Произошла ошибка при остановке бота.');
        } finally {
            setLoading(prev => ({ ...prev, stop: false }));
        }
    };

    const handleCheckStatus = async () => {
        setLoading(prev => ({ ...prev, check: true }));
        try {
            await onCheckStatus();
        } catch (error) {
            console.error('Ошибка при проверке статуса бота:', error);
            alert('Произошла ошибка при проверке статуса бота.');
        } finally {
            setLoading(prev => ({ ...prev, check: false }));
        }
    };

    return (
        <div className="admin-bot-control-widget">
            <h4>Управление ботами Dota 2</h4>
            <Button
                variant="primary"
                onClick={handleRestartBot}
                disabled={loading.restart}
                className="widget-button"
            >
                {loading.restart ? <Spinner as="span" animation="border" size="sm" /> : <FiRefreshCw />}
                {' '}Перезапустить бота
            </Button>
            <Button
                variant="danger"
                onClick={() => setShowConfirmStop(true)}
                disabled={loading.stop}
                className="widget-button"
            >
                {loading.stop ? <Spinner as="span" animation="border" size="sm" /> : <FiStopCircle />}
                {' '}Остановить бота
            </Button>
            <Button
                variant="info"
                onClick={handleCheckStatus}
                disabled={loading.check}
                className="widget-button"
            >
                {loading.check ? <Spinner as="span" animation="border" size="sm" /> : <FiCheckCircle />}
                {' '}Проверить статус бота
            </Button>

            {/* Модальное окно подтверждения остановки бота */}
            <Modal show={showConfirmStop} onHide={() => setShowConfirmStop(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Подтверждение остановки</Modal.Title>
                </Modal.Header>
                <Modal.Body>Вы уверены, что хотите остановить бота? Это действие необратимо.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmStop(false)}>
                        Отмена
                    </Button>
                    <Button variant="danger" onClick={handleStopBot}>
                        Остановить
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DOTA2BotControlViget;