import { useNavigate } from 'react-router-dom';
import { ITeamStoreModel } from '../../models/dto/team/ITeamStoreModel';
import './TeamCard.css';
import starCaptainImg from '../../assets/img/LobbyIndicators/starСaptain.svg';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import TeamHistory from '../TeamHistory/TeamHistory';
interface TeamCardProps {
    currentTeamInfo: ITeamStoreModel;
    isManagePage?: boolean;
}

const TeamCard: React.FC<TeamCardProps> = ({ currentTeamInfo, isManagePage }) => {
    const navigate = useNavigate();
    const { player } = useContext(AppContext);

    return (
        <div className="teamCard_team_container">
            <div className="teamCard_team_container_content">
                <div className='teamCard_team_gameName'>
                    {isManagePage && (
                        <>
                            {currentTeamInfo.gameTypeName}
                        </>
                    )}
                </div>
                <div className="teamCard_team_container_content_header">
                    <img src={process.env.REACT_APP_IMG_STORAGE_URL + (currentTeamInfo.logoUrl || 'default_avatar.png')} />
                    <div className="teamCard_team_title" onClick={() => { navigate(`/team/${currentTeamInfo.teamId}`) }}>
                        <h2>{currentTeamInfo.name} ({currentTeamInfo.tag})</h2>
                        {currentTeamInfo?.captainId === player?.playerId && isManagePage && (
                            <div className='team_editButton card' onClick={() => navigate(`/update-team/${currentTeamInfo.teamId}`)}>
                                Редактирование
                            </div>
                        )}
                        {currentTeamInfo.website && (
                            <a href={currentTeamInfo.website} target="_blank" rel="noopener noreferrer">Вебсайт</a>
                        )}
                    </div>
                </div>

                <div className="teamCard_players_grid">
                    {currentTeamInfo.players.map(player => (
                        <div key={player.playerId} className="teamCard_player_card" onClick={() => { navigate(`/player/${player.playerId}`); }}>
                            {player.playerId === currentTeamInfo.captainId && <img alt='captain' className='teamCaptainImg' src={starCaptainImg} />}
                            <img src={process.env.REACT_APP_IMG_STORAGE_URL + (player.avatarURL || 'default_avatar.png')} alt={`${player.name} ${player.surname}`} className="teamCard_player_avatar" />
                            <div className="teamCard_player_info">
                                <h4>{player.name} {player.surname}</h4>
                                <h4>{player.login}</h4>
                                {player.skills && <p>Навыки: {player.skills}</p>}
                                {player.preferredPositions && <p>Предпочтения: {player.preferredPositions}</p>}
                                {player.telegramLink && (
                                    <a href={`${process.env.REACT_APP_TG_URL}${player.telegramLink.replace(/^@/, '')}`}>
                                        Telegram
                                    </a>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {isManagePage && (
                <>
                    <TeamHistory gameTypeId={currentTeamInfo.gameTypeId} />
                    <hr />
                </>
            )}
        </div>
    );
};

export default TeamCard;