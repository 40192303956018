import { useState } from "react";
import './MyCarousel.css';
import { ICommonTournamentModel } from "../../models/dto/tournament/ICommonTournamentModel";
import Carousel from "react-spring-3d-carousel";
import { useSwipeable } from "react-swipeable";
import { config } from "react-spring";
import TournamentsItem from "../Tournaments/TournamentItem";
import { getGameTypeName } from "../../logic/CommonLogic";

interface CarrousselProps {
    tournaments: ICommonTournamentModel[];
    offset?: number;
    showArrows?: boolean;
    width?: string;
    height?: string;
    margin?: string;
}

function MyCarousel({
    tournaments,
    offset = 4,
    showArrows = false,
    width = '100%',
    height = '40vh',
    margin = '0 auto'
}: CarrousselProps) {
    const slides = tournaments.map((tournament, index) => {
        return {
            key: index,
            content: (
                <TournamentsItem
                    key={index}
                    name={tournament.name}
                    gameTypeId={tournament.gameTypeId}
                    logoUrl={process.env.REACT_APP_IMG_STORAGE_URL + tournament.logoUrl}
                    startDate={tournament.startDate}
                    maxSlots={tournament.maxSlots}
                    activeSlots={tournament.activeSlots}
                    tournamentId={tournament.tournamentId}
                />
            ),
            onClick: () => setGoToSlide(index)
        };
    });

    const [goToSlide, setGoToSlide] = useState<number | undefined>(undefined);
    const [cards] = useState(slides);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => handleSwipe('next'),
        onSwipedRight: () => handleSwipe('prev'),
        trackTouch: true,
        trackMouse: true
    });

    const handleSwipe = (direction: 'next' | 'prev') => {
        if (direction === 'next') {
            const nextSlide = goToSlide !== undefined && goToSlide < cards.length - 1 ? goToSlide + 1 : 0;
            setGoToSlide(nextSlide);
        } else {
            const prevSlide = goToSlide !== undefined && goToSlide > 0 ? goToSlide - 1 : cards.length - 1;
            setGoToSlide(prevSlide);
        }
    };

    return (
        <div className="no-select" {...swipeHandlers} style={{ width, height, margin }}>
            <Carousel
                slides={cards}
                goToSlide={goToSlide}
                offsetRadius={offset}
                showNavigation={showArrows}
                animationConfig={config.gentle}
            />
        </div>
    );
}

export default MyCarousel; 