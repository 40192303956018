import { useNavigate } from 'react-router-dom';
import { ITeamStoreModel } from '../../../models/dto/team/ITeamStoreModel';
import GooeyButton from '../../Buttons/GooeyButton/GooeyButton';
import TeamCard from '../../TeamCard/TeamCard';
import './DOTA2PlayerProfile.css';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';

interface DOTA2PlayerProfileProps {
    playerId: string;
    currentTeamInfo?: ITeamStoreModel;
}

const DOTA2PlayerProfile: React.FC<DOTA2PlayerProfileProps> = ({ playerId, currentTeamInfo }) => {
    const navigate = useNavigate();
    const { player } = useContext(AppContext);
    
    return (
        <div className="dota2PlayerProfile_container">
            {currentTeamInfo ? (
                <TeamCard currentTeamInfo={ currentTeamInfo } />
            ) : (
                <>
                    {playerId === player?.playerId ? (
                        <GooeyButton onClick={() => navigate('/create-team')} isLoading={false} disabled={false}>
                            СОЗДАТЬ КОМАНДУ
                        </GooeyButton>
                    ) : (
                        <div>
                            Нет команды
                        </div>
                    )}
                </>
            )}
            <div className='dota2PlayerProfile_spinnerContainer'>
            </div>
        </div>
    );
};

export default DOTA2PlayerProfile;