import React, { useEffect, useState } from 'react';
import './TournamentManage.css';
import { ICommonTournamentModel } from '../../../../../models/dto/tournament/ICommonTournamentModel';
import { getTournamentsByFilters } from '../../../../../http/tournament/tournamentActionsAPI';
import { Button, Form, Pagination } from 'react-bootstrap';
import { TournamentConstants } from '../../../../../helpers/constants/tournamentConstants';
import CustomSpinner1 from '../../../../../components/CustomSpinners/CustomSpinner1';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import { approveTournament } from '../../../../../http/adminActionsAPI';
import { TournamentSF } from '../../../../../viewModels/filters/TournamentSF';
import { useNavigate } from 'react-router-dom';
import { TournamentStatusesEnum } from '../../../../../models/enums/tournament/TournamentStatusesEnum';

const TournamentManage: React.FC = () => {
    const navigate = useNavigate();
    const [fetchingTournamentsList, setFetchingTournamentsList] = useState(true);
    const [tournamentsList, setTournamentsList] = useState<ICommonTournamentModel[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const tournamentsPerPage = 8;
    const [gameTypeIdsSelected, setGameTypeIdsSelected] = useState<string[]>([TournamentConstants.DOTA2.gameTypeId]);
    const [statuses, setStatuses] = useState<TournamentStatusesEnum[]>([TournamentStatusesEnum.WaitForStartMatches]);

    const [filters, setFilters] = useState<TournamentSF>({
        gameTypeIds: gameTypeIdsSelected,
        tournamentStatuses: statuses,
        page: 1,
        limit: tournamentsPerPage
    });

    useEffect(() => {
        setFilters(prevFilters => ({
            ...prevFilters,
            tournamentStatuses: statuses,
            gameTypeIds: gameTypeIdsSelected,
            page: currentPage,
        }));
    }, [gameTypeIdsSelected, currentPage, statuses]);

    useEffect(() => {
        setFetchingTournamentsList(true);
        getTournamentsByFilters(filters)
            .then(data => {
                if (data) {
                    setTournamentsList(data.tournaments);
                    const totalCount = data.totalCount;
                    if (filters.limit) {
                        const calculatedTotalPages = Math.ceil(totalCount / filters.limit);
                        setTotalPages(calculatedTotalPages > 0 ? calculatedTotalPages : 1);
                    }
                }
            })
            .finally(() => setFetchingTournamentsList(false));
    }, [filters]);

    const handleGameTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setGameTypeIdsSelected([event.target.value]);
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedStatus = Number(event.target.value) as TournamentStatusesEnum;
        setStatuses([selectedStatus]);
    };

    const handleApprove = (tournamentId: string) => {
        setFetchingTournamentsList(true);
        approveTournament(tournamentId).then(() => {
            getTournamentsByFilters(filters)
                .then(data => {
                    if (data) {
                        const tournament = data.tournaments;
                        const totalCount = data.totalCount;
                        setTournamentsList(tournament);
                        if (filters.limit) {
                            const calculatedTotalPages = Math.ceil(totalCount / filters.limit);
                            setTotalPages(calculatedTotalPages > 0 ? calculatedTotalPages : 1);
                        }
                    }
                })
                .finally(() => setFetchingTournamentsList(false));
        });
    };

    const formatDateRange = (startDate: string, endDate: string): string => {
        const start = new Date(startDate).toLocaleDateString();
        const end = new Date(endDate).toLocaleDateString();
        return `${start} - ${end}`;
    };

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const renderPaginationItems = () => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                >
                    {number}
                </Pagination.Item>,
            );
        }
        return items;
    };

    return (
        <div className='tournaments_manage_tab_container'>
            <h4>Управление турнирами</h4>
            <Button onClick={() => { window.open('/create-tournament', '_blank') }}>Создать турнир</Button>
            <div className='current_tournaments_unit'>
                <div className='current_tournaments_unit_table'>
                    <div className='all_tourn_header'>Все турниры</div>
                    <div className='tournaments_manage_filter_container'>
                        <Form.Select
                            className="tournaments_manage_filter"
                            name="gameTypeIdsSelected"
                            value={gameTypeIdsSelected}
                            required
                            onChange={handleGameTypeChange}
                        >
                            <option value={TournamentConstants.CS2.gameTypeId}>CS2</option>
                            <option value={TournamentConstants.DOTA2.gameTypeId}>DOTA2</option>
                        </Form.Select>
                        <Form.Select
                            name="status"
                            value={statuses[0]}
                            onChange={handleStatusFilterChange}
                        >
                            <option value={TournamentStatusesEnum.WaitForApprove}>WaitForApprove</option>
                            <option value={TournamentStatusesEnum.Approved}>Approved</option>
                            <option value={TournamentStatusesEnum.Registration}>Registration</option>
                            <option value={TournamentStatusesEnum.WaitForStartMatches}>WaitForStartMatches</option>
                            <option value={TournamentStatusesEnum.GroupStage}>GroupStage</option>
                            <option value={TournamentStatusesEnum.MainStage}>MainStage</option>
                            <option value={TournamentStatusesEnum.Cancelled}>Cancelled</option>
                            <option value={TournamentStatusesEnum.Finished}>Finished</option>
                        </Form.Select>
                    </div>
                    {fetchingTournamentsList ? (
                        <CustomSpinner1 />
                    ) : (
                        <table className='current_tournaments_table'>
                            <thead>
                                <tr>
                                    <th className='current_tournaments_table_header name'>Название</th>
                                    <th className='current_tournaments_table_header'>Даты</th>
                                    <th className='current_tournaments_table_header'>Свободно мест</th>
                                    <th className='current_tournaments_table_header'>Статус</th>
                                    <th className='current_tournaments_table_header'>Одобрен организатором</th>
                                    <th className='current_tournaments_table_header'>Одобрен Админом</th>
                                    <th className='current_tournaments_table_header'>Редактировать</th>
                                    <th className='current_tournaments_table_header'>Администрирование</th>
                                    <th className='current_tournaments_table_header'>Удалить</th>
                                </tr>
                            </thead>
                            <tbody className='current_tournaments_table_body'>
                                {tournamentsList.map(tournament => (
                                    <tr key={tournament.gameTypeId}>
                                        <td className='current_tournaments_table_td name' onClick={() => { window.open(`/tournament/${tournament.tournamentId}`, '_blank') }}>
                                            {tournament.name}
                                        </td>
                                        <td className='current_tournaments_table_td'>{formatDateRange(tournament.startDate, tournament.endDate)}</td>
                                        <td className='current_tournaments_table_td'>{tournament.maxSlots - tournament.activeSlots}</td>
                                        <td className='current_tournaments_table_td'>{tournament.statusName}</td>
                                        <td className={`current_tournaments_table_td ${tournament.approvedByOrganisator ? 'approved' : 'not-approved'}`}>
                                            {tournament.approvedByOrganisator ? 'Одобрен' : 'Не одобрен'}
                                        </td>
                                        <td className={`current_tournaments_table_td ${tournament.approvedByAdmin ? 'approved' : 'not-approved'}`}>
                                            {tournament.approvedByAdmin ? 'Одобрен' :
                                                <Button className='current_tournaments_table_button' onClick={() => handleApprove(tournament.tournamentId)}>Одобрить</Button>
                                            }
                                        </td>
                                        <td className='current_tournaments_table_td' onClick={() => navigate(`/update-tournament/${tournament.tournamentId}`)}>
                                            <PencilFill className='general_icon' />
                                        </td>
                                        <td className='current_tournaments_table_td name'>
                                            <Button variant='warning' className='current_tournaments_table_button'>
                                                <a href={`/admin-panel/manual-tournament/${tournament.tournamentId}`} target="_blank" rel="noopener noreferrer">
                                                    MANUAL
                                                </a>
                                            </Button>
                                        </td>
                                        <td className='current_tournaments_table_td'>
                                            <TrashFill className='general_icon' />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className='current_tournaments_pagination'>
                    <Pagination>
                        {renderPaginationItems()}
                    </Pagination>
                </div>
            </div >
        </div >
    );
};

export default TournamentManage;