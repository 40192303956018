import { useContext, useEffect, useState } from "react";
import { ITableStatRow } from "../../../models/dto/stats/common/ITableStatRow";
import { getPlayerMatchesTableStat } from "../../../http/statistics/cs2StatAPI";
import CS2LastActivityStatistics from "../CS2LastActivityStatistics/CS2LastActivityStatistics";
import './CS2UserProfile.css';
import PlayerStatTable from "../../PlayerStatTable/PlayerStatTable";
import StatisticsIsNotFound from "../../StatisticsIsNotFound/StatisticsIsNotFound";
import SoonEmpty from "../../SoonEmpty/SoonEmpty";
import { ITeamStoreModel } from "../../../models/dto/team/ITeamStoreModel";
import { useNavigate } from "react-router-dom";
import TeamCard from "../../TeamCard/TeamCard";
import GooeyButton from "../../Buttons/GooeyButton/GooeyButton";
import { AppContext } from "../../AppContext";

const tableData: ITableStatRow[] = [
    {
        matchId: '-',
        kda: 0,
        date: "01.01.25",
        subjects: '',
        kills: 0,
        deaths: 0,
        assists: 0,
        hs: 0,
        mvp: 0,
    },
    {
        matchId: '-',
        kda: 345,
        date: "01.01.25",
        subjects: '',
        kills: 0,
        deaths: 0,
        assists: 0,
        hs: 0,
        mvp: 0,
    },
    {
        matchId: '-',
        kda: 0,
        date: "01.01.25",
        subjects: '',
        kills: 0,
        deaths: 0,
        assists: 0,
        hs: 0,
        mvp: 0,
    }
];

interface CS2UserProfileProps {
    playerId: string;
    currentTeamInfo?: ITeamStoreModel;
}

const CS2UserProfile: React.FC<CS2UserProfileProps> = ({ playerId, currentTeamInfo }) => {
    const { player } = useContext(AppContext);
    const [activeTab, setActiveTab] = useState<'defaultStat' | 'extendedStat'>('defaultStat');
    const [lastMtchesStatData, setLastMtchesStatData] = useState<ITableStatRow[]>(tableData);
    const [isNotStat, setNotStat] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    
    const navigate = useNavigate();
    
    useEffect(() => {
        if (playerId) {
            getPlayerMatchesTableStat(playerId, 5)
                .then((responce) => {
                    if (responce && responce.length > 0) {
                        setLastMtchesStatData(responce);
                        setNotStat(false);
                    } else {
                        console.log('Ошибка при получении последних матчей');
                    }
                })
                .catch(() => {
                    console.log('Ошибка при получении последних матчей');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [playerId]);
    
    return (
        <div className="cs2UserProfile_container">
            {currentTeamInfo ? (
                <TeamCard currentTeamInfo={ currentTeamInfo } />
            ) : (
                <>
                    {playerId === player?.playerId ? (
                        <GooeyButton onClick={() => navigate('/create-team')} isLoading={false} disabled={false}>
                            СОЗДАТЬ КОМАНДУ
                        </GooeyButton>
                    ) : (
                        <div>
                            Нет команды
                        </div>
                    )}
                </>
            )}
            <div className='cs2UserProfile_tabs'>
                <button className={`cs2UserProfile_tab ${activeTab === 'defaultStat' ? 'active' : ''}`} onClick={() => setActiveTab('defaultStat')}>Общая статистика</button>
                <button className={`cs2UserProfile_tab ${activeTab === 'extendedStat' ? 'active' : ''}`} onClick={() => setActiveTab('extendedStat')}>Подробная статистика</button>
            </div>
            
            {activeTab === 'defaultStat' ? (
                <>
                    <div className='cs2UserProfile_second_container'>
                        <div className='cs2UserProfile_second_upperBlock'>
                            <h5>ПОСЛЕДНИЙ МАТЧ</h5>
                            <hr />
                                <CS2LastActivityStatistics playerId={playerId || null} statType='match' />
                            <hr />
                        </div>
                        
                        <div className='cs2UserProfile_second_lowerBlock'>
                            <h5>ПОСЛЕДНИЙ ТУРНИР</h5>
                            <hr />
                                <CS2LastActivityStatistics playerId={playerId || null} statType='tournament' />
                            <hr />
                        </div>
                    </div>
                    
                    <div className='cs2UserProfile_third_container'>
                        <h5>ПОСЛЕДНИЕ МАТЧИ</h5>
                        <hr />
                            <div className='cs2UserProfile_stat_second_levels'>
                                <StatisticsIsNotFound disabled={isNotStat && !isLoading}>
                                    <div className='cs2UserProfile_stat_second_level1'>
                                        <div className='cs2UserProfile_detailedstat_container'>
                                            <PlayerStatTable data={lastMtchesStatData || []} isMatchPage={false} isLoading={isLoading} />
                                            <div className='cs2UserProfile_detailedstat'>
                                            </div>
                                        </div>
                                    </div>
                                </StatisticsIsNotFound>
                            </div>
                    </div>
                </>
            ) : (
                <SoonEmpty text="Открытие скоро" />
                // <CS2PlayerStats />
            )}
        </div>
    );
};

export default CS2UserProfile;