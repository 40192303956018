import React, { ChangeEvent, useEffect, useState } from 'react';
import { ICS2MapModel } from '../../../../../models/dto/cs2/ICS2MapModel';
import { getAllMaps } from '../../../../../http/tournament/cs2TournamentActionsAPI';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { useModal } from '../../../../../hooks/useModal';
import './Cs2Manage.css';
import { ICS2UpdateMapVM } from '../../../../../viewModels/tournament/ICS2UpdateMapVM';
import { createCS2Map, deleteCS2Map, updateCS2Map } from '../../../../../http/adminActionsAPI';
import { PencilFill, X } from 'react-bootstrap-icons';
import MyAlert from '../../../../../components/MyAlert/MyAlert';

const Cs2Manage: React.FC = () => {
    const [allMaps, setAllMaps] = useState<ICS2MapModel[]>([]);
    const { isVisible: showMapModal, openModal: openMapModal, closeModal: closeMapModal } = useModal();
    const { isVisible: showMyAlert, openModal: handleShowMyAlert, closeModal: handleCloseMyAlert } = useModal();
    const [editingMapId, setEditingMapId] = useState<string | null>(null);
    const [map, setMap] = useState<ICS2UpdateMapVM>({
        mapName: '',
        mapImgUrl: undefined,
        isCompetitive: false,
    });

    useEffect(() => {
        getAllMaps()
            .then((r) => {
                setAllMaps(r as ICS2MapModel[]);
            })
            .catch((r) => {
                console.log(`Getting map error: ${r}`);
            });
    }, [])

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setMap((prevMap) => ({
            ...prevMap,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            setMap((prevMap) => ({
                ...prevMap,
                mapImgUrl: file,
            }));
        }
    };

    const handleCreateMap = async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        try {
            const form = event.currentTarget as HTMLFormElement;

            if (form.checkValidity() === false) {
                event.stopPropagation();
                return;
            }
            const formData = new FormData();
            if (map.mapImgUrl) {
                formData.append('mapImgUrl', map.mapImgUrl);
            }
            formData.append('mapName', map.mapName ?? '');
            formData.append('isCompetitive', map.isCompetitive ? 'true' : 'false');

            await createCS2Map(formData);
            closeMapModal();
            getAllMaps()
                .then((r) => {
                    setAllMaps(r as ICS2MapModel[]);
                })
                .catch((r) => {
                    console.log(`Getting map error: ${r}`);
                });

        } catch (error) {
            console.error('Create map error:', error);
        }
    };

    const handleUpdateMap = async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        try {
            const form = event.currentTarget as HTMLFormElement;

            if (form.checkValidity() === false) {
                event.stopPropagation();
                return;
            }
            const formData = new FormData();
            if (map.mapImgUrl) {
                formData.append('mapImgUrl', map.mapImgUrl);
            }
            formData.append('mapName', map.mapName ?? '');
            formData.append('isCompetitive', map.isCompetitive ? 'true' : 'false');

            if (editingMapId) {
                await updateCS2Map(formData, editingMapId);
                closeMapModal();
                getAllMaps()
                    .then((r) => {
                        setAllMaps(r as ICS2MapModel[]);
                    })
                    .catch((r) => {
                        console.log(`Getting map error: ${r}`);
                    });
            }
        } catch (error) {
            console.error('Update map error:', error);
        }
    };

    const handleDeleteMap = async (mapId: string) => {
        await deleteCS2Map(mapId);
        getAllMaps()
            .then((r) => {
                setAllMaps(r as ICS2MapModel[]);
            })
            .catch((r) => {
                console.log(`Getting map error: ${r}`);
            });
    };

    const handleEditMap = (map: ICS2MapModel) => {
        setMap({
            mapName: map.name,
            mapImgUrl: undefined,
            isCompetitive: map.isCompetitive,
        });
        setEditingMapId(map.mapId);
        openMapModal();
    };

    const handleCloseMapModal = () => {
        setMap({
            mapName: '',
            mapImgUrl: undefined,
            isCompetitive: false,
        });
        setEditingMapId(null);
        closeMapModal();
    };
    return (
        <div className='tournaments_manage_tab_container'>
            <h4>Управление картами CS2</h4>
            <div className='current_tournaments_maps'>
                <div className='all_tourn_header'>Все CS2 карты</div>
                <Button onClick={openMapModal}>Добавить карту</Button>
                <Modal className="cs2_modal" show={showMapModal} onHide={closeMapModal} centered backdrop="static">
                    <Form onSubmit={editingMapId ? handleUpdateMap : handleCreateMap}>
                        <Modal.Body>
                            <FloatingLabel label="Название карты" className="mb-3">
                                <Form.Control
                                    required
                                    name="mapName"
                                    placeholder=""
                                    value={map.mapName}
                                    onChange={handleInputChange}
                                    autoComplete='off'
                                    maxLength={50}
                                />
                            </FloatingLabel>
                            <Form.Label>Картинка</Form.Label>
                            <Form.Control
                                type="file"
                                name="mapImgUrl"
                                onChange={handleFileChange}
                            />
                            <Form.Check
                                type='checkbox'
                                label="Competitive"
                                name="isCompetitive"
                                checked={map.isCompetitive}
                                onChange={handleInputChange}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseMapModal}>
                                Закрыть
                            </Button>
                            <Button variant="primary" type="submit">
                                {editingMapId ? 'Сохранить изменения' : 'Создать'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                <div className='сs2_mapselection_maps'>
                    {allMaps.map((map) => (
                        <>
                            <div
                                className='cs2_map_container'
                                key={map.mapId}
                                style={{
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${process.env.REACT_APP_IMG_STORAGE_URL}${map.mapImgUrl})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    border: '2px solid rgba(0, 0, 0, 0.5)',
                                    borderRadius: '10px'
                                }}>
                                {map.isCompetitive === true && <p className="competitive_overlay">COMPETITIVE</p>}
                                <h3 className='сs2_mapselection_maps_title'>{map.name}</h3>
                                <X className='cs2_delete_icon' onClick={() => { handleShowMyAlert() }} />
                                <PencilFill className='cs2_pen_icon' onClick={() => handleEditMap(map)} />
                            </div>
                            <MyAlert
                                onHide={handleCloseMyAlert}
                                show={showMyAlert}
                                alertText='Вы точно хотите удалить карту?'
                                buttons={[
                                    {
                                        text: 'Да',
                                        onClick: () => {
                                            handleDeleteMap(map.mapId);
                                            handleCloseMyAlert();
                                        }
                                    },
                                    { text: 'Нет', onClick: handleCloseMyAlert }
                                ]}
                            />
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Cs2Manage;