import React, { useEffect, useState } from 'react';
import './ResultsTable.css';
import { ITournamentGroupPlacesModel } from '../../models/dto/tournament/ITournamentGroupPlacesModel';
import { ITournamentPlacesModel } from '../../models/dto/tournament/ITournamentPlacesModel';
import { getTournamentPlaces } from '../../http/adminActionsAPI';
import CustomSpinner1 from '../CustomSpinners/CustomSpinner1';


const ResultsTable: React.FC<{ tournamentId: string }> = ({ tournamentId }) => {
    const [tournamentResults, setTournamentResults] = useState<ITournamentPlacesModel>();
    const [fetchingResults, setFetchingResults] = useState(false);

    useEffect(() => {
        const fetchResults = async () => {
            setFetchingResults(true);
            if (tournamentId) {
                const result = await getTournamentPlaces(tournamentId);
                if (typeof result === 'string') {
                    setFetchingResults(false);
                    alert(`Error getting results: ${result}`);
                } else {
                    setFetchingResults(false);
                    setTournamentResults(result);
                }
            }
        };

        fetchResults();
    }, [tournamentId]);

    const renderGroupStageTable = (groupPlaces: ITournamentGroupPlacesModel) => (
        <div className='groupstage_tables'>
            <div>
                <h4>Группа {groupPlaces.groupNumber}</h4>
                <div className='results_robin_table'>
                    {groupPlaces.teamPlaces.map((team) => (
                        <div key={team.teamId}>
                            <div className='results_robin_table_row'>
                                {team.teamName || "-"}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <h4>Места</h4>
                <div className='results_robin_table'>
                    {groupPlaces.teamPlaces.map((team) => (
                        <div key={team.teamId}>
                        {team.placeEnd !== team.placeStart ? (
                            <div className='results_playoff_table_row '>
                                {team.placeStart} - {team.placeEnd}
                            </div>
                        ) : (
                            <div className='results_playoff_table_row '>
                                {team.placeStart}
                            </div>
                            )}
                    </div>
                    ))}
                </div>
            </div>
            {/* <div>
                <div className="result_table_row header">
                    <div className="result_table_cell header">W</div>
                    <div className="result_table_cell header">L</div>
                    <div className="result_table_cell header">D</div>
                    <div className="result_table_cell header">PTS.</div>
                </div>
                <div className='results_points'>
                    {groupPlaces.teamPlaces.map((row, index) => (
                        <div key={index} className="result_table_row">
                            <div className="result_table_cell">{row.placeStart}</div>
                            <div className="result_table_cell">{row.placeStart}</div>
                            <div className="result_table_cell">{row.placeStart}</div>
                            <div className="result_table_cell">{row.placeStart}</div>
                        </div>
                    ))}
                </div>
            </div> */}
        </div>
    );

    const renderEliminationStageTable = (groupPlaces: ITournamentGroupPlacesModel) => (
        <div className='groupstage_tables'>
            <div>
                <h4>Группа {groupPlaces.groupNumber}</h4>
                <div className='results_robin_table'>
                    {groupPlaces.teamPlaces.map((team) => (
                        <div key={team.teamId}>
                            <div className='results_playoff_table_row '>
                                {team.teamName || "-"}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <h4>Места</h4>
                <div className='results_robin_table'>
                    {groupPlaces.teamPlaces.map((team) => (
                        <div key={team.teamId}>
                            {team.placeEnd !== team.placeStart ? (
                                <div className='results_playoff_table_row '>
                                    {team.placeStart} - {team.placeEnd}
                                </div>
                            ) : (
                                <div className='results_playoff_table_row '>
                                    {team.placeStart}
                                </div>
                                )}
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <div className="result_table_row header">
                    <div className="result_table_cell header">W</div>
                    <div className="result_table_cell header">L</div>
                </div>
                <div className='results_points_playoff'>
                    {groupPlaces.teamPlaces.map((row, index) => (
                        <div key={index} className="result_table_row">
                            <div className="result_table_cell">{row.placeStart}</div>
                            <div className="result_table_cell">{row.placeStart}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
    return (
        <>
            {fetchingResults ? (
                <CustomSpinner1 />
            ) : (
                <div className='results_container'>
                    <h2>Результаты турнира</h2>
                    {tournamentResults && tournamentResults.tournamentStagePlaces.map((stage) => (
                        <div key={stage.stageNumber} className='results_container_tables'>
                            {stage.stageType === 'OnlyRobin' || stage.stageType === 'RobinWithSingle' || stage.stageType === 'RobinWithDouble' ? (
                                <div>
                                    <h4>Группы</h4>
                                    {stage.groupPlacesModels.map((group) => (
                                        <div key={group.groupNumber}>
                                            {renderGroupStageTable(group)}
                                        </div>
                                    ))}
                                </div>
                            ) : null}

                            {stage.stageType === 'OnlySingle' || stage.stageType === 'RobinWithSingle' || stage.stageType === 'OnlyDouble' || stage.stageType === 'RobinWithDouble' ? (
                                <div>
                                    <h4>Плей-офф</h4>
                                    {stage.groupPlacesModels.map((group) => (
                                        <div key={group.groupNumber}>
                                            {renderEliminationStageTable(group)}
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default ResultsTable;