import { authInstance } from "..";

export const createDota2Tournament = async (
    tournamentData: FormData
): Promise<string | false> => {
    try {
        const response = await authInstance.post('Dota2Tournaments/CreateTournament', tournamentData);
        const { tournamentId } = response.data;
        if (response.status === 200 && tournamentId) {
            return tournamentId;
        } else {
            console.log('Unexpected response or missing tournamentId');
            return false;
        }
    } catch (e: any) {
        console.log(e.response?.data?.message || 'Error creating tournament');
        return false;
    }
};

export const updateDota2Tournament = async (
    tournamentData: FormData,
    tournamentId: string
): Promise<string | false> => {
    try {
        const response = await authInstance.put(`Dota2Tournaments/UpdateTournament/?tournamentId=${tournamentId}`, tournamentData);
        return response.data;
    } catch (e: any) {
        console.error(e.response?.data?.message || 'Error creating tournament', e);
        return false;
    }
};