import { guestInstance } from "..";
import { IMatchStats } from "../../models/dto/stats/common/IMatchStats";
import { ITotalShootingStats } from "../../models/dto/stats/common/ITotalShootingStats";

import { ICS2MatchStatistics } from "../../models/dto/stats/cs2/playerStats/ICS2MatchStatistics";
import { ICS2TournamentStatistics } from "../../models/dto/stats/cs2/playerStats/ICS2LastTournamentStatistics";
import { ITableStatRow } from "../../models/dto/stats/common/ITableStatRow";
import { CS2WeaponsEnum } from "../../models/enums/cs2/CS2WeaponsEnum";
import { ICS2WeaponStatistics } from "../../models/dto/stats/cs2/playerStats/ICS2WeaponStatistics";
import { ICS2MapStatistics } from "../../models/dto/stats/cs2/playerStats/ICS2MapStatistics";
import { IMapTableStat } from "../../models/dto/stats/common/IMapTableStat";

export const getLastPlayerMatchStat = async (
    playerId: string
): Promise<ICS2MatchStatistics    | null> => {
    try {
        const response = await guestInstance.get(`CS2Stats/GetLastPlayerMatchStat`, {
            params: { playerId }
        });
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения статистики последнего матча');
        return null;
    }
};

export const getLastPlayerTournamentStat = async (
    playerId: string
): Promise<ICS2TournamentStatistics  | null> => {
    try {
        const response = await guestInstance.get('CS2Stats/GetLastPlayerTournamentStat', {
            params: { playerId }
        });
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения статистики последнего турнира');
        return null;
    }
};

export const getPlayerMapStat = async (
    playerId: string,
    mapName: string
): Promise<ICS2MapStatistics  | null> => {
    try {
        const response = await guestInstance.get('CS2Stats/GetPlayerMapStat', {
            params: { playerId, mapName }
        });
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения статистики матча');
        return null;
    }
};

export const getPlayerWeaponStat = async (
    playerId: string,
    weapon: CS2WeaponsEnum
): Promise<ICS2WeaponStatistics | null> => {
    try {
        const response = await guestInstance.get('CS2Stats/GetPlayerWeaponStat', {
            params: { playerId, weapon }
        });
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения статистики матча');
        return null;
    }
};

export const getPlayerShootingStat = async (
    playerId: string
): Promise<ITotalShootingStats[] | null> => {
    try {
        const response = await guestInstance.get('CS2Stats/GetPlayerShootingStat', {
            params: { playerId }
        });
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения статистики стрельбы');
        return null;
    }
};

export const getPlayerMatchesTableStat = async (
    playerId: string,
    countLastOf?: number
): Promise<ITableStatRow[] | null> => {
    try {
        const response = await guestInstance.get('CS2Stats/GetPlayerMatchesTableStat', {
            params: { playerId, countLastOf }
        });
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения табличной статистики игрока');
        return null;
    }
};

export const getMapsTableStatsByMatch = async (
    matchId: string
): Promise<IMapTableStat[] | null> => {
    try {
        const response = await guestInstance.get('CS2Stats/GetMapsTableStatsByMatch', {
            params: { matchId }
        });
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения табличной статистики матча');
        return null;
    }
};

export const getPlayerMatchStat = async (
    playerId: string,
    matchId: string
): Promise<ICS2MatchStatistics | null> => {
    try {
        const response = await guestInstance.get('CS2Stats/GetPlayerMatchStat', {
            params: { playerId, matchId }
        });
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения статистики матча');
        return null;
    }
};


// ! НИЖЕ НЕ ПРОВЕРЯЛ

export const getMatchStats = async (
    matchId: string
): Promise<IMatchStats | false> => {
    try {
        const response = await guestInstance.get(`CS2Stats/GetMatchStats?matchId=${matchId}`);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения статистики матча');
        return false;
    }
};