import { useNavigate } from 'react-router-dom';
import { ITableStatRow } from '../../models/dto/stats/common/ITableStatRow';
import { IBaseEntity } from '../../models/IBaseEntity';
import CustomSpinner1 from '../CustomSpinners/CustomSpinner1';
import './PlayerStatTable.css';  

import defaultImg1 from '../../assets/img/UserAvatars/UserAvatar10.png'
import defaultImg2 from '../../assets/img/UserAvatars/UserAvatar8.png'

interface PlayerStatTableProps {
    data: ITableStatRow[];
    isMatchPage: boolean;
    isLoading?: boolean;
}

const PlayerStatTable: React.FC<PlayerStatTableProps> = ({ data, isMatchPage, isLoading }) => {
    const navigate = useNavigate();

    const parseSubjects = (subjects: string | IBaseEntity[]) => {
        if (typeof subjects === 'string' && subjects.trim() !== '' && subjects !== '[]') {
            try {
                return JSON.parse(subjects);
            } catch (error) {
                console.error("Failed to parse subjects:", error);
                return [];
            }
        } else if (Array.isArray(subjects)) {
            return subjects;
        } else {
            return [];
        }
    }

    const handleTeamClick = (e: React.MouseEvent, teamId: string) => {
        e.stopPropagation();
        navigate(`/team/${teamId}`);
    };

    const handleMatchClick = (matchId: string) => {
        navigate(`/match/${matchId}`);
    };

    return (
        <table className='user_stat_table'>
            {isLoading ? (
                <div className='user_stat_table_spinnerContainer'>
                    <CustomSpinner1 sizeSpinnerContainer='100%' size='100px' />
                </div>
            ) : (
                <>
                    <thead>
                        <tr>
                            {data.some(row => row.date) && <th></th>}
                            <th></th>
                            <th className='user_stat_table_header'>KILLS</th>
                            <th className='user_stat_table_header'>DEATHS</th>
                            <th className='user_stat_table_header'>ASSISTS</th>
                            <th className='user_stat_table_header'>HEADSHOTS</th>
                            <th className='user_stat_table_header'>MVP</th>
                        </tr>
                    </thead>
                    <tbody className='user_stat_table_body'>
                        {data.map((row, index) => {
                            const subjects = parseSubjects(row.subjects);
                            return (
                                <tr key={index} className='user_stat_table_row'>
                                    {row.date && <td className='user_stat_table_td'>{row.date}</td>}
                                    <td className='user_stat_table_td teams'>
                                        <div className={`user_stat_table_td teams_container ${isMatchPage ? '' : 'pStat'}`}>
                                            {isMatchPage ? (
                                                <div className='user_stat_table_td_row_team_container'>
                                                    <img
                                                        src={subjects[0]?.LogoUrl ? `${process.env.REACT_APP_IMG_STORAGE_URL}${subjects[0].LogoUrl}` : ''}
                                                        className='user_stat_table_td_img'
                                                        alt={subjects.length >= 1 && subjects[0] ? subjects[0].Name : ""}
                                                    />
                                                    <div className='user_stat_table_td_row_team player' onClick={() => navigate(`/player/${subjects[0].Id}`)}>
                                                        {subjects.length === 1 && subjects[0].Name}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='user_stat_table_td_row_team_mainContainer' onClick={() => handleMatchClick(row.matchId)}>
                                                    <div className='user_stat_table_td_row_team_container' onClick={(e) => handleTeamClick(e, subjects[0].Id)}>
                                                        <img
                                                            src={subjects[0]?.LogoUrl ? `${process.env.REACT_APP_IMG_STORAGE_URL}${subjects[0].LogoUrl}` : defaultImg1}
                                                            className='user_stat_table_td_img'
                                                            alt={subjects.length >= 1 && subjects[0] ? subjects[0].Name : ""}
                                                        />
                                                        <div className='user_stat_table_td_row_team'>
                                                            {(subjects.length >= 1 && subjects[0]) ? subjects[0]?.Name : "TBD"}
                                                        </div>
                                                    </div>
                                                    
                                                    <span>
                                                        VS
                                                    </span>
                                                    
                                                    <div className='user_stat_table_td_row_team_container second' onClick={(e) => handleTeamClick(e, subjects[1].Id)}>
                                                        <div className='user_stat_table_td_row_team'>
                                                            {(subjects.length === 2 && subjects[1]) ? subjects[1]?.Name : "TBD"}
                                                        </div>
                                                        <img
                                                            src={subjects.length === 2 && subjects[1]?.LogoUrl ? `${process.env.REACT_APP_IMG_STORAGE_URL}${subjects[1].LogoUrl}` : defaultImg2}
                                                            className='user_stat_table_td_img'
                                                            alt={subjects.length === 2 && subjects[1] ? subjects[1].Name : ""}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td className='user_stat_table_td'>{row.kills}</td>
                                    <td className='user_stat_table_td'>{row.deaths}</td>
                                    <td className='user_stat_table_td'>{row.assists}</td>
                                    <td className='user_stat_table_td'>{row.hs}%</td>
                                    <td className='user_stat_table_td'>{row.mvp}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </>
            )}
        </table>
    );
};

export default PlayerStatTable;