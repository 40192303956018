import React, { useEffect, useState } from 'react';
import './CS2LastActivityStatistics.css';
import { MatchResultEnum } from '../../../models/enums/MatchResultEnum';
import { ICS2TournamentStatistics } from '../../../models/dto/stats/cs2/playerStats/ICS2LastTournamentStatistics';
import { ICS2MatchStatistics } from '../../../models/dto/stats/cs2/playerStats/ICS2MatchStatistics';
import { getLastPlayerMatchStat, getLastPlayerTournamentStat } from '../../../http/statistics/cs2StatAPI';
import { List } from 'react-bootstrap-icons';
import CustomSpinner1 from '../../CustomSpinners/CustomSpinner1';
import StatisticsIsNotFound from '../../StatisticsIsNotFound/StatisticsIsNotFound';

import tourImg from '../../../assets/img/tournaments_avatars/tournament_avatar1.jpg';
import matchImg1 from '../../../assets/img/mapsWithLogo/cs2_ancientWithLogo.jpg';
import formatDate from '../../../helpers/functions/formatDate';
import { useNavigate } from 'react-router-dom';

const lastMatch: ICS2MatchStatistics = {
    matchId: '-',
    result: MatchResultEnum.Victory,
    teamOneName: 'Team',
    teamTwoName: 'Team',
    mapStats: [
        {
            img: matchImg1,
            date: '01.01.25',
            kda: 0.00,
            adr: 0.00,
            mvp: 0,
            kills: 0,
            deaths: 0,
            assists: 0,
            mapName: 'Ancient'
        }
    ]
}
const lastTournament: ICS2TournamentStatistics = {
    img: tourImg,
    date: '01.01.25',
    kda: 0.00,
    adr: 0.00,
    mvp: 0,
    kills: 0,
    deaths: 0,
    assists: 0,
    startPlace: 0,
    endPlace: 0,
    tournamentTitle: 'MEVA Cup',
    mapName: 'Ancient'
};

interface ICS2LastActivityStatisticsProps {
    playerId: string | null;
    statType: 'match' | 'tournament';
}

function isTournamentDataComplete(tournament: ICS2TournamentStatistics): boolean {
    const requiredFields: (keyof ICS2TournamentStatistics)[] = [
        "img",
        "date",
        "kda",
        "adr",
        "mvp",
        "kills",
        "deaths",
        "assists",
        "startPlace",
        "endPlace",
        "tournamentTitle",
        "mapName"
    ];
    
    return requiredFields.every((field) => tournament[field] !== undefined && tournament[field] !== null);
}

const CS2LastActivityStatistics: React.FC<ICS2LastActivityStatisticsProps> = ({ playerId, statType }) => {
    const [playerStatLastTournamentData, setPlayerStatLastTournamentData] = useState<ICS2TournamentStatistics>(lastTournament);
    const [playerStatLastMatchData, setPlayerStatLastMatchData] = useState<ICS2MatchStatistics>(lastMatch);
    const [currentMap, setCurrentMap] = useState<number>(0);
    const [isMapListVisible, setMapListVisible] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isNotStat, setNotStat] = useState<boolean>(true);
    const [isNotMapStat, setNotMapStat] = useState<boolean>(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (playerId) {
            if (statType === 'match') {
                getLastPlayerMatchStat(playerId)
                    .then((data) => {
                        if (data) {
                            if (data.mapStats.length < 1 && data.matchId) {
                                setNotMapStat(true);
                            } else {
                                setPlayerStatLastMatchData(data);
                                setNotStat(false);
                                setCurrentMap(0);
                            }
                        } else {
                            setPlayerStatLastMatchData(lastMatch);
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching match stats:', error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else if (statType === 'tournament') {
                getLastPlayerTournamentStat(playerId)
                    .then((data) => {
                        if (data) {
                            if (isTournamentDataComplete(data) && data.tournamentTitle) {
                                setNotMapStat(true);
                            } else {
                                setPlayerStatLastTournamentData(data);
                                setNotStat(false);
                            }
                        } else {
                            setPlayerStatLastTournamentData(lastTournament);
                        }
                    })
                    .catch((error) => {
                        setPlayerStatLastMatchData(lastMatch);
                        setPlayerStatLastTournamentData(lastTournament);
                        console.error('Error fetching tournament stats:', error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                console.log(`Incorrect statType: ${statType}`);
            }
        }
    }, [playerId, statType]);
    
    return (
        <StatisticsIsNotFound 
            text={isNotMapStat ? (statType === 'match' ? 'Статистика последнего матча недоступна' : 'Статистика последнего турнира недоступна') : 'Нет статистики'}
            disabled={isNotStat && !isLoading}
        >
            <div className="lastActivityStatistics_container">
                {isLoading ? (
                    <div className='lastActivityStatistics_spinnerContainer'>
                        <CustomSpinner1 sizeSpinnerContainer='100%' size='100px' />
                    </div>
                ) : (
                    <>
                        <div className='lastActivityStatistics_firstBlock'>
                            <div className='lastActivityStatistics_firstBlock_imgContainer'>
                                {(statType === 'match' && playerStatLastMatchData.mapStats.length > 1) && (
                                    <div className='swapMapIcon_wrapper'>
                                        <List className='swapMapIcon' onClick={() => setMapListVisible(!isMapListVisible)} />
                                        {isMapListVisible && (
                                            <div className=''>
                                                {playerStatLastMatchData?.mapStats.map((m, idx) => (
                                                    <p className={`${currentMap === idx ? 'active' : ''}`} key={idx} onClick={() => { setCurrentMap(idx); setMapListVisible(false) }}>{m.mapName}</p>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                                
                                <img className='lastActivityStatistics_firstBlock_img'
                                    src={
                                        statType === 'match'
                                            ?
                                            isNotStat ? matchImg1 : process.env.REACT_APP_IMG_STORAGE_URL + playerStatLastMatchData?.mapStats[currentMap]?.img
                                            :
                                            isNotStat ? tourImg : process.env.REACT_APP_IMG_STORAGE_URL + playerStatLastTournamentData?.img} alt={'img'} />
                                
                                <div className='lastActivityStatistics_firstBlock_resultBlock'>
                                    <div className='lastActivityStatistics_firstBlock_result date'>
                                        {statType === 'match'
                                            ? formatDate(playerStatLastMatchData?.mapStats[currentMap]?.date ?? '')
                                            : formatDate(playerStatLastTournamentData?.date ?? '')}
                                    </div>
                                    <div
                                        className={`lastActivityStatistics_firstBlock_result result ${statType === 'match' ? playerStatLastMatchData?.result === MatchResultEnum.Victory ? 'win' : playerStatLastMatchData?.result === MatchResultEnum.Defeat ? 'loss' : '' : ''}`}
                                    >
                                        {statType === 'match' ? (
                                            <>
                                                {playerStatLastMatchData?.result === MatchResultEnum.Victory && <>ПОБЕДА</>}
                                                {playerStatLastMatchData?.result === MatchResultEnum.Defeat && <>ПОРАЖЕНИЕ</>}
                                                {playerStatLastMatchData?.result === MatchResultEnum.Draw && <>НИЧЬЯ</>}
                                            </>
                                        ) : (
                                            <>
                                                {playerStatLastTournamentData && (
                                                    <>
                                                        {playerStatLastTournamentData.startPlace === playerStatLastTournamentData.endPlace
                                                            ? `${playerStatLastTournamentData.startPlace}`
                                                            : `${playerStatLastTournamentData.startPlace} - ${playerStatLastTournamentData.endPlace}`}
                                                        МЕСТО
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            
                            <div className='lastActivityStatistics_table'>
                                <div className='lastActivityStatistics_table_matchOrTournamentTitle'>
                                    {statType === 'tournament' && playerStatLastTournamentData && 'tournamentTitle' in playerStatLastTournamentData ? (
                                        <div className='lastActivityStatistics_table_tournamemtTitle'>
                                            {playerStatLastTournamentData.tournamentTitle}
                                        </div>
                                    ) : (
                                        playerStatLastMatchData && 'teamOneName' in playerStatLastMatchData && 'teamTwoName' in playerStatLastMatchData && (
                                            <div className='lastActivityStatistics_table_matchTitleContainer' onClick={() => navigate(`/match/${playerStatLastMatchData.matchId}`)}>
                                                <div className='lastActivityStatistics_table_matchTitle'>
                                                    {playerStatLastMatchData.teamOneName}
                                                </div>
                                                <span>VS</span>
                                                <div className='lastActivityStatistics_table_matchTitle'>
                                                    {playerStatLastMatchData.teamTwoName}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                                
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='lastActivityStatistics_table_th'>KDA</th>
                                            <th className='lastActivityStatistics_table_th center'>ADR</th>
                                            <th className='lastActivityStatistics_table_th'>MVP</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        {statType === 'match' ? (
                                            <tr>
                                                <td className='lastActivityStatistics_table_td'>{playerStatLastMatchData?.mapStats[currentMap]?.kda}</td>
                                                <td className='lastActivityStatistics_table_td center'>{playerStatLastMatchData?.mapStats[currentMap]?.adr}</td>
                                                <td className='lastActivityStatistics_table_td'>{playerStatLastMatchData?.mapStats[currentMap]?.mvp}</td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td className='lastActivityStatistics_table_td'>{playerStatLastTournamentData?.kda}</td>
                                                <td className='lastActivityStatistics_table_td center'>{playerStatLastTournamentData?.adr}</td>
                                                <td className='lastActivityStatistics_table_td'>{playerStatLastTournamentData?.mvp}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                        <div className='lastActivityStatistics_secondBlock'>                
                            <div className='lastActivityStatistics_secondBlock_title'>СТАТИСТИКА ПОСЛЕДНЕГО {statType === 'match' ? 'МАТЧА' : 'ТУРНИРА'}</div>
                            <div className='lastActivityStatistics_secondBlock_circles'>
                                <div className='lastActivityStatistics_secondBlock_circleContainer'>
                                    <div className='lastActivityStatistics_secondBlock_circle'>
                                        {statType === 'match' ? playerStatLastMatchData?.mapStats[currentMap]?.kills : playerStatLastTournamentData?.kills}
                                    </div>
                                    <div className='lastActivityStatistics_secondBlock_circleTitle kills'>K</div>
                                </div>
                                
                                <div className='lastActivityStatistics_secondBlock_circleContainer'>
                                    <div className='lastActivityStatistics_secondBlock_circle death'>
                                        {statType === 'match' ? playerStatLastMatchData?.mapStats[currentMap]?.deaths : playerStatLastTournamentData?.deaths}
                                    </div>
                                    <div className='lastActivityStatistics_secondBlock_circleTitle'>D</div>
                                </div>
                                
                                <div className='lastActivityStatistics_secondBlock_circleContainer'>
                                    <div className='lastActivityStatistics_secondBlock_circle sapport'>
                                        {statType === 'match' ? playerStatLastMatchData?.mapStats[currentMap]?.assists : playerStatLastTournamentData?.assists}
                                    </div>
                                    <div className='lastActivityStatistics_secondBlock_circleTitle'>A</div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </StatisticsIsNotFound>
    );
};

export default CS2LastActivityStatistics;