import React, { useEffect, useRef, useState } from "react"
import './Styles/Bracket.css';
import { BracketData } from "./Inerfaces"
import SingleElimination from "./components/SingleElimination/SingleElimination";
import DoubleElimination from "./components/DoubleElimination/DoubleElimination";
import RoundRobin from "./components/RoundRobin/RoundRobin";
import CustomSpinner1 from "../CustomSpinners/CustomSpinner1";

interface BracketDataProps {
    bracketData: BracketData;
    editMode: boolean;
    isAdmin: boolean;
    playerTeamId?: string;
}

const Bracket: React.FC<BracketDataProps> = ({ bracketData, editMode, isAdmin, playerTeamId }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [zoom, setZoom] = useState(100);
    const [minZoom, setMinZoom] = useState(100);
    const [elementSize, setElementSize] = useState({ width: 0, height: 0 });
    const [isLoading, setLoading] = useState<boolean>(false);
    
    const adjustZoomToFitRounds = () => {
        const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 0;
        const roundWidth = 195;
        const totalRoundsWidth = bracketData.round.length * roundWidth - 75;
        
        const calculatedMinZoom = containerWidth > 0 ? (containerWidth / totalRoundsWidth) * 100 : 100;
        setMinZoom(calculatedMinZoom);
        setZoom(calculatedMinZoom);
    };
    
    useEffect(() => {
        if (containerRef.current) {
            setElementSize({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight
            });
        }
    }, []);
    
    useEffect(() => {
        adjustZoomToFitRounds();
        
        const handleResize = () => {
            adjustZoomToFitRounds();
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [bracketData]);
    
    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            event.preventDefault();
            const maxZoom = 150;
            
            setZoom((currentZoom) => {
                let newZoom = currentZoom + (event.deltaY < 0 ? 5 : -5);
                return Math.max(minZoom, Math.min(maxZoom, newZoom));
            });
        };
        
        const div = containerRef.current;
        div?.addEventListener('wheel', handleWheel, { passive: false });
        
        return () => {
            div?.removeEventListener('wheel', handleWheel);
        };
    }, [minZoom]);
    
    useEffect(() => {
        const adjustSizes = () => {
            if (containerRef.current) {
                const scale = zoom / 100;
                setElementSize({
                    width: containerRef.current.offsetWidth * scale,
                    height: containerRef.current.offsetHeight * scale
                });
            }
        };
        
        adjustSizes();
        window.addEventListener('resize', adjustSizes);
        return () => window.removeEventListener('resize', adjustSizes);
    }, [zoom]);
    
    useEffect(() => {
        if (!groups || !matches || !participants || !rounds || !stages) {
            setLoading(true);
        } else {
            renderBracketByType();
        }
    }, [bracketData])
    
    // Все массивы
    const groups = bracketData.group;
    const matches = bracketData.match;
    const participants = bracketData.participant;
    const rounds = bracketData.round;
    const stages = bracketData.stage;
    const matchGame = bracketData.match_game;
    
    // Проверка массиовов
    if (!groups) {console.log('Error: groups');}
    if (!matches) {console.log('Error: matches');}
    if (!participants) {console.log('Error: participants');}
    if (!rounds) {console.log('Error: rounds');}
    if (!stages) {console.log('Error: stages');}
    
    const renderBracketByType = () => {
        return stages.map((stage, idx) => {
            const stageId = stage.id;
            const currentStageGroups = groups.filter(g => g.stage_id === stageId);
            const currentStageRounds = rounds.filter(r => r.stage_id === stageId);
            const currentStageMatches = matches.filter(m => m.stage_id === stageId);
            
            const stageName = stages.length < 1 || idx === 0 ? 'Основная стадия' : 'Дополнительная стадия';
            
            return (
                <div className="breacket_mainContainer_wrapper" key={idx}>
                    <h3 className="stage_name">
                        {stageName}
                    </h3>
                    <div className="breacket_mainContainer" style={{ overflowY: editMode ? 'auto' : 'hidden' }}>
                        {stage.type === 'single_elimination' && (
                            <SingleElimination
                                participants={participants}
                                rounds={currentStageRounds}
                                matches={currentStageMatches}
                                stages={[stage]}
                                groups={currentStageGroups}
                                match_game={matchGame}
                                editMode={editMode}
                                isAdmin={isAdmin}
                                playerTeamId={`${playerTeamId}`}
                            />
                        )}
                        {stage.type === 'double_elimination' && (
                            <DoubleElimination
                                participants={participants}
                                rounds={currentStageRounds}
                                matches={currentStageMatches}
                                stages={[stage]}
                                groups={currentStageGroups}
                                match_game={matchGame}
                                editMode={editMode}
                                isAdmin={isAdmin}
                                playerTeamId={`${playerTeamId}`}
                            />
                        )}
                        {stage.type === 'round_robin' && (
                            <RoundRobin
                                participants={participants}
                                rounds={currentStageRounds}
                                matches={currentStageMatches}
                                groups={currentStageGroups}
                                stages={stages}
                                match_game={matchGame}
                                editMode={editMode}
                                isAdmin={isAdmin}
                                playerTeamId={`${playerTeamId}`}
                                currentStage={idx}
                            />
                        )}
                        {!['single_elimination', 'double_elimination', 'round_robin'].includes(stage.type) && (
                            <h1>{stage.type} в разработке...</h1>
                        )}
                    </div>
                </div>
            );
        });
    };
    
    return (
        <>
            {isLoading ? (
                <CustomSpinner1 />
            ) : (
                <>
                    {renderBracketByType()}
                </>
            )}
        </>
    );
}

export default Bracket;