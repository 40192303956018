import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserHeader.css';
import { Badge, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IPlayerPageModel } from '../../models/dto/player/IPlayerPageModel';
import { IEditPlayerProfileModel } from '../../models/IEditPlayerProfileModel';
import { Discord, PatchCheckFill, Plus, PlusCircleFill, Steam, Telegram, XLg } from 'react-bootstrap-icons';
import expandIcon from '../../assets/img/toExpand_icon.svg';
import collapseIcon from '../../assets/img/collapse_icon.svg';
import { AppContext } from '../AppContext';
import { getPLayerInfo, updatePlayer } from '../../http/userAPI';
import ButtonWithLoader from '../Buttons/ButtonWithLoader/ButtonWithLoader';
import CustomSpinner1 from '../CustomSpinners/CustomSpinner1';

interface IUserHeader {
    playerId: string | null;
    editMode?: boolean;
}

const UserHeader: React.FC<IUserHeader> = ({ playerId, editMode }) => {
    const { player } = useContext(AppContext);
    const [playerData, setPlayerData] = useState<IPlayerPageModel>();
    const [editData, setEditData] = useState<IEditPlayerProfileModel>({});
    const [isMyPlayerPage, setIsMyPlayerPage] = useState(false)
    const [currentAvatar, setCurrentAvatar] = useState<string | undefined>();
    const [isExpanded, setIsExpanded] = useState(false);
    const [profileEditing, setProfileEditing] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const discordTarget = useRef(null);
    const telegramTarget = useRef(null);
    const [showAddTelegram, setShowAddTelegram] = useState(false);
    const [showAddDiscord, setShowAddDiscord] = useState(false);
    const [visibleSubmitButton, setVisibleSubmitButton] = useState(false);
    const [infoVisible, setInfoVisible] = useState(false);
    const [isStreamer, setIsStreamer] = useState(editData.isStreamer || false);
    const expandIconRotate = isExpanded ? collapseIcon : expandIcon;
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [fSizePlayerLogin, setFSizePlayerLogin] = useState<number>();
    
    useEffect(() => {
        if (playerData?.login) {
            const loginLength = playerData?.login.length;
            
            if (loginLength) {
                setFSizePlayerLogin(
                    loginLength <= 10 ? 30 
                    : loginLength > 10 && loginLength <= 15 ? 23
                    : 18
                );
            }
        }
    }, [playerData?.login]);
    
    const toggleStreamer = () => setIsStreamer(!isStreamer);
    let steamAuthUri: string = `${process.env.REACT_APP_BACKEND_API_URL}steamauth/${player?.userId}`;
    
    const userHeaderContainer_className =
        isExpanded && !profileEditing && playerData?.isStreamer ? 'medium' :
        isExpanded && playerData?.isStreamer ? 'streamer' :
        isExpanded && playerData?.isStreamer ? 'streamer' :
        isExpanded && profileEditing ? 'streamer' :
        isExpanded && !playerData?.isStreamer ? 'big' :
        '';
    
    const fetchPlayerData = async () => {
        if (playerId) {
            try {
                const data = await getPLayerInfo(playerId);
                if (data) {
                    setPlayerData(data);
                    setIsMyPlayerPage(data.playerId === player?.playerId);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Fetch Player Data error:', error);
            }
        }
    };
    
    useEffect(() => {
        fetchPlayerData();
    }, [playerId]);
    
    const getAvatarSrc = () => {
        if (currentAvatar) return currentAvatar;
        if (playerData?.avatarURL) return `${process.env.REACT_APP_IMG_STORAGE_URL}${playerData.avatarURL}`;
    };
    
    const handleEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked, files } = event.target;
        if (type === 'checkbox') {
            setEditData(prevData => ({
                ...prevData,
                [name]: checked
            }));
        } else if (files) {
            const imageUrl = URL.createObjectURL(files[0]);
            setCurrentAvatar(imageUrl);
            setEditData(prevData => ({
                ...prevData,
                [name]: files[0]
            }));
        } else {
            setEditData(prevData => ({
                ...prevData,
                [name]: value,
            }));
        }
    };
    
    const renderTooltipContent = (name: string, value: string) => {
        return profileEditing && (
            <input
                type='text'
                name={name}
                value={value || ''}
                onChange={handleEdit}
                placeholder={`Ваш @username`}
                className='userHeader_socials_tooltip_input'
                maxLength={32}
                autoComplete="off"
            />
        )
    };
    
    const handleExpandClick = () => {
        if (!isExpanded) {
            setIsExpanded(true);
            setTimeout(() => {
                setInfoVisible(true);
            }, 300);
        } else {
            setInfoVisible(false);
            setProfileEditing(false);
            setVisibleSubmitButton(false);
            setTimeout(() => {
                setIsExpanded(false);
            }, 0);
        }
    };
    
    const startEditing = () => {
        setTimeout(() => {
            setVisibleSubmitButton(true);
        }, 400);
        setEditData({
            firstName: playerData?.name ?? '',
            middleName: playerData?.middlename ?? '',
            surName: playerData?.surname ?? '',
            city: playerData?.city ?? '',
            telegramLink: playerData?.telegramLink ?? '',
            discordLink: playerData?.discordLink ?? '',
            isStreamer: playerData?.isStreamer ?? false,
            streamNickName: playerData?.streamNickName ?? '',
            streamLink: playerData?.streamLink ?? ''
        });
        setProfileEditing(true);
    };
    
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };
    
    const handleSubmitUpdate = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            
            if (editData.avatarFile) { formData.append('avatarFile', editData.avatarFile) }
            if (editData.firstName !== playerData?.name) { formData.append('firstName', editData.firstName ?? '') }
            if (editData.middleName !== playerData?.middlename) { formData.append('middleName', editData.middleName ?? '') }
            if (editData.surName !== playerData?.surname) { formData.append('surName', editData.surName ?? '') }
            if (editData.city !== playerData?.city) { formData.append('city', editData.city ?? '') }
            // if (editData.role !== playerData.role) {formData.append('role', editData.role ?? '')}
            if (editData.telegramLink !== playerData?.telegramLink) {
                formData.append('telegramLink', editData.telegramLink ?? '');
            }
            if (editData.discordLink !== playerData?.discordLink) {
                formData.append('discordLink', editData.discordLink ?? '');
            }
            formData.append('isStreamer', editData.isStreamer?.toString() ?? '')
            
            if (editData.isStreamer) {
                if (!editData.streamNickName || !editData.streamLink) {
                    alert('Укажите стримерскую информацию!');
                    setIsLoading(false);
                    return;
                }
                
                formData.append('streamNickName', editData.streamNickName);
                formData.append('streamLink', editData.streamLink);
            }
            
            if (playerId) {
                try {
                    await updatePlayer(formData, playerId);
                    await fetchPlayerData();
                } catch (error) {
                    console.error('Ошибка при обновлении аккаунта:', error);
                    alert('Произошла ошибка при обновлении аккаунта!');
                } finally {
                    setIsLoading(false);
                }
            } else {
                alert('Произошла ошибка: playerId отсутствует!');
            }
        } catch (error) {
            console.error('Update Player error:', error);
        }
        
        setVisibleSubmitButton(false);
        setProfileEditing(false);
    };
    
    return (
        <div className={`userHeader_container ${userHeaderContainer_className}`}>
            {isLoading ? (
                <CustomSpinner1 />
            ) : (
                <div className='userHeader_staticContainer'>
                    <div className='startProfileEditingButton_container'>
                        {isExpanded && (
                            <>
                                {(playerData?.playerId === player?.playerId && editMode) && (
                                    <>
                                        {!profileEditing ? (
                                            <button className='startProfileEditingButton' onClick={startEditing}>РЕДАКТИРОВАНИЕ</button>
                                        ) : (
                                            <XLg className='stopProfileEditingButton' size={20} onClick={() => {
                                                setProfileEditing(false);
                                                setShowAddTelegram(false);
                                                setShowAddDiscord(false);
                                                setVisibleSubmitButton(false);
                                            }} />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    
                    <div className='userHeader_avatarContainer'>
                        <img className='userHeader_avatar' src={getAvatarSrc()} alt='avatar' />
                        {isExpanded && (
                            <>
                                {profileEditing && (
                                    <>
                                        <PlusCircleFill id="upfile1" className='userHeader_inputAvatar_upload' onClick={() => { fileInputRef.current?.click() }} />
                                        <input type="file" ref={fileInputRef} name="avatarFile" className="userHeader_inputAvatar" onChange={handleEdit} />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    
                    <div className='userHeader_infoContainer'>
                        <div className='userHeader_userName' style={{fontSize: `${fSizePlayerLogin}px`}}>
                            {playerData?.login}
                            {playerData?.isStreamer && (
                                <a href={playerData?.streamLink || '#'} target="_blank" rel="noopener noreferrer">
                                    <Badge className='userHeader_' pill bg="primary">
                                        СТРИМЕР
                                    </Badge>
                                </a>
                            )}
                        </div>
                        
                        <div className='userHeader_socialLinksContainer'>
                            {playerData?.steamID ? (
                                <div className='userHeader_socialIcon'>
                                    <a href={`${process.env.REACT_APP_STEAM_PROFILE_URL}${playerData?.steamID}`}>
                                        <Steam size={20} className='userCard_icon' />
                                    </a>
                                </div>
                            ) : (
                                <div className={'userHeader_socialIcon disabled'}>
                                    {profileEditing ? (
                                        <a href={steamAuthUri}>
                                            <Steam size={20} className='userCard_icon' />
                                        </a>
                                    ) : (
                                        <Steam size={20} className='userCard_icon' />
                                    )}
                                </div>
                            )}
                            
                            {/* <div className={`userHeader_socialIcon ${!playerData?.discordLink ? 'disabled' : ''}`}>
                                {isExpanded && profileEditing && (
                                    <div ref={discordTarget} className='input_socials'>
                                        <Plus onClick={() => { setShowAddDiscord(prevState => !prevState) }} />
                                    </div>
                                )}
                                <OverlayTrigger
                                    placement="top"
                                    overlay={playerData?.discordLink ? (
                                        <Tooltip>
                                            {playerData?.discordLink}
                                        </Tooltip>
                                    ) : <></>}
                                >
                                    <Discord size={20} className='userHeader_icon' />
                                </OverlayTrigger>
                                
                                <Overlay target={discordTarget.current} show={showAddDiscord} placement="top">
                                    {(props) => (
                                        <Tooltip  {...props}>
                                            {renderTooltipContent('discordLink', editData.discordLink || '')}
                                        </Tooltip>
                                    )}
                                </Overlay>
                            </div> */}
                            
                            <div className={`userHeader_socialIcon ${!playerData?.discordLink ? 'disabled' : ''}`}>
                                {isExpanded && (
                                    <>
                                        {profileEditing && (
                                            <div ref={telegramTarget}>
                                                <Plus className='input_socials' onClick={() => { setShowAddTelegram(prevState => !prevState) }} />
                                            </div>
                                        )}
                                    </>
                                )}
                                <OverlayTrigger
                                    placement="top"
                                    overlay={playerData?.telegramLink ? (
                                        <Tooltip>
                                            {playerData?.telegramLink}
                                        </Tooltip>
                                    ) : <></>}
                                >
                                    <a>
                                        <Telegram size={20} className='userHeader_icon' />
                                    </a>
                                </OverlayTrigger>
                                <Overlay target={telegramTarget.current} show={showAddTelegram} placement="top">
                                    {(props) => (
                                        <Tooltip {...props}>
                                            {renderTooltipContent('telegramLink', editData.telegramLink || '')}
                                        </Tooltip>
                                    )}
                                </Overlay>
                            </div>
                            
                            {false ? (
                                <div className='userHeader_socialIcon'>
                                    <a>
                                        <PatchCheckFill size={20} />
                                    </a>
                                </div>
                            ) : (
                                <div className={'userHeader_socialIcon disabled'}>
                                    <PatchCheckFill size={20} />
                                </div>
                            )}
                        </div>
                        
                        <div className='userHeader_raitingContainer'>
                            <div className='userHeader_raitingTitle'>РЕЙТИНГ</div>
                            <div className='userHeader_raiting'>
                                <div className='userCard_secondContainer_inDevelop'>
                                    {'1000'} <span>PTS</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/*<div className='userHeader_userTeamContainer'>*/}
                    {/*    <div*/}
                    {/*        className='userHeader_userTeamCard'*/}
                    {/*        onClick={() => {*/}
                    {/*            if (!playerData?.teamId && playerData?.playerId === player?.playerId) {*/}
                    {/*                navigate(`/create-team`);*/}
                    {/*            } else if (playerData?.teamId) {*/}
                    {/*                navigate(`/team/${playerData?.teamId}`);*/}
                    {/*            } else {*/}
                    {/*                return;*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <div className='userHeader_userTeamCardTitle'>*/}
                    {/*            КОМАНДА*/}
                    {/*        </div>*/}
                    {/*        {!playerData?.teamId && playerData?.playerId === player?.playerId ? (*/}
                    {/*            <div className='userHeader_userTeamName'>Создать команду</div>*/}
                    {/*        ) : !playerData?.teamId ? (*/}
                    {/*            <div className='userHeader_userTeamName disabled'>Нет команды</div>*/}
                    {/*        ) : (*/}
                    {/*            <div className='userHeader_userTeamName'>{playerData?.teamName}</div>*/}
                    {/*        )}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            )}
            
            {infoVisible && (
                <>
                    {!profileEditing ? (
                        <div className='userHeader_fullUserInfoContainer'>
                            <div className='userHeader_infoBlock'>
                                <span>ФАМИЛИЯ</span>
                                <div>{playerData?.surname ?? '-'}</div>
                            </div>
                            
                            <div className='userHeader_infoBlock'>
                                <span>ИМЯ</span>
                                <div>{playerData?.name ?? '-'}</div>
                            </div>
                            
                            <div className='userHeader_infoBlock'>
                                <span>ОТЧЕСТВО</span>
                                <div>{playerData?.middlename ?? '-'}</div>
                            </div>
                            
                            <div className='userHeader_infoBlock'>
                                <span>ДАТА РОЖДЕНИЯ</span>
                                <div>{playerData?.birthDate ? formatDate(playerData.birthDate) : '-'}</div>
                            </div>
                            
                            <div className='userHeader_infoBlock'>
                                <span>ГОРОД</span>
                                <div>{playerData?.city ?? '-'}</div>
                            </div>
                            
                            <div className='userHeader_infoBlock'>
                                <span>РОЛЬ</span>
                                {/* <div>{playerData?.role ?? '-'}</div> */}
                                <div>-</div>
                            </div>
                            
                            {playerData?.isStreamer && (
                                <>
                                    <div className='userHeader_infoBlock streamerCheckbox'>
                                        <input
                                            type="checkbox"
                                            checked={playerData?.isStreamer}
                                            onChange={toggleStreamer}
                                        />
                                        <span>СТРИМЕР</span>
                                    </div>
                                    <div className='userHeader_infoBlock'>
                                        <span>НИК</span>
                                        <div>{playerData?.streamNickName ?? '-'}</div>
                                    </div>
                                    
                                    <div className='userHeader_infoBlock'>
                                        <span>ССЫЛКА НА КАНАЛ</span>
                                        <div>{playerData?.streamLink ?? '-'}</div>
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        <div className='userHeader_fullUserInfoContainer'>
                            <form onSubmit={handleSubmitUpdate} >
                                <div className='userHeader_infoBlock'>
                                    <span>ФАМИЛИЯ</span>
                                    <input type="text" name="surName" value={editData?.surName} onChange={handleEdit} autoFocus autoComplete="off" />
                                </div>
                                
                                <div className='userHeader_infoBlock'>
                                    <span>ИМЯ</span>
                                    <input type="text" name="firstName" value={editData?.firstName} onChange={handleEdit} autoComplete="off" />
                                </div>
                                
                                <div className='userHeader_infoBlock'>
                                    <span>ОТЧЕСТВО</span>
                                    <input type="text" name="middleName" value={editData?.middleName} onChange={handleEdit} autoComplete="off" />
                                </div>
                                
                                <div className='userHeader_infoBlock'>
                                    <span>ДАТА РОЖДЕНИЯ</span>
                                    <div>{playerData?.birthDate ? formatDate(playerData.birthDate) : '-'}</div>
                                </div>
                                
                                <div className='userHeader_infoBlock'>
                                    <span>ГОРОД</span>
                                    <input type="text" name="city" value={editData?.city} onChange={handleEdit} autoComplete="off" />
                                </div>
                                
                                <div className='userHeader_infoBlock'>
                                    <span>РОЛЬ</span>
                                    <input type="text" name="role" value={editData?.role} onChange={handleEdit} autoComplete="off" />
                                </div>
                                
                                <div className='userHeader_infoBlock streamerCheckbox'>
                                    <input
                                        type="checkbox"
                                        checked={editData.isStreamer}
                                        onChange={handleEdit}
                                        name='isStreamer'
                                    />
                                    <span>СТРИМЕР</span>
                                </div>
                                
                                {editData.isStreamer && (
                                    <>
                                        <div className='userHeader_infoBlock'>
                                            <span>НИКНЕЙМ</span>
                                            <input type="text" name="streamNickName" value={editData?.streamNickName ?? ''} onChange={handleEdit} autoComplete="off" />
                                        </div>
                                        <div className='userHeader_infoBlock'>
                                            <span>ССЫЛКА НА КАНАЛ</span>
                                            <input type="text" name="streamLink" value={editData?.streamLink ?? ''} onChange={handleEdit} autoComplete="off" />
                                        </div>
                                    </>
                                )}
                            </form>
                        </div>
                    )}
                    {visibleSubmitButton &&
                        <div className='userHeader_submitButton'>
                            <ButtonWithLoader
                                isLoading={isLoading}
                                disabled={isLoading}
                                onClick={handleSubmitUpdate}
                            >
                                СОХРАНИТЬ
                            </ButtonWithLoader>
                        </div>
                    }
                </>
            )}
            
            <img src={expandIconRotate} alt='expand' className='userHeader_expandIcon' onClick={handleExpandClick} />
        </div>
    );
};

export default UserHeader;