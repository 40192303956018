import React, { useEffect, useState } from 'react';
import { Modal, Form, Alert} from 'react-bootstrap';
import './RegModalWindow.css';
import { IRegModalWindowProps } from './IRegModalWindow';
import { signup } from '../../../http/userAPI';
import { IUserRegistrationViewModel } from '../../../viewModels/registration/IUserRegistrationViewModel';
import ButtonWithLoader from '../../Buttons/ButtonWithLoader/ButtonWithLoader';

const RegModalWindow: React.FC<IRegModalWindowProps> = ({ showModal, closeModal }) => {
    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<IUserRegistrationViewModel>({
        email: '',
        password: '',
        confirmPassword: '',
        userName: '',
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [countdown, setCountdown] = useState(60);
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };
    
    useEffect(() => {
        if (isButtonDisabled && countdown > 0) {
            const timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
            
            return () => clearInterval(timer);
        } else if (countdown === 0) {
            setIsButtonDisabled(false);
            setShowAlert(false); 
        }
    }, [isButtonDisabled, countdown]);
    
    const handleSignup = async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);
        
        const form = event.currentTarget as HTMLFormElement;
        
        if (form.checkValidity() === false) {
            setAlertMessage("Заполните необходимые поля");
            setShowAlert(true);
            setIsLoading(false);
            return;
        } else {
            setValidated(true);
        }
        if (formData.password !== formData.confirmPassword) {
            setPasswordsMatch(false); 
            setIsError(true);
            setAlertMessage("Пароли не совпадают");
            setShowAlert(true);
            setIsLoading(false);
            return; 
        }
        signup(formData)
            .then(result => {
                if (result === null) {
                    setIsError(false);
                    setIsLoading(false);
                    setShowAlert(true);
                    setAlertMessage('Проверьте почту для завершения регистрации.');
                    setCountdown(60);
                    setIsButtonDisabled(true);
                } else if (result.includes("Exception while sending email")) {
                    setIsError(true);
                    setAlertMessage("Попробуйте через минуту");
                    setShowAlert(true);
                    setIsLoading(false);
                    setCountdown(60);
                    setIsButtonDisabled(true);
                } else if (
                    result.includes("Пароль должен содержать не менее 1 цифры") || 
                    result.includes("Пароль должен содержать не менее 6 символов") || 
                    result.includes("Пароль должен содержать не менее 1 заглавной буквы") || 
                    result.includes("Почтовый адрес 'r.efremenko13@gmail.com' уже занят")
                ) {
                    setIsError(true);
                    setAlertMessage(result);
                    setShowAlert(true);
                    setIsLoading(false);
                } else {
                    setIsError(true);
                    setAlertMessage(result);
                    setShowAlert(true);
                    setIsLoading(false);
                    setCountdown(60);
                    setIsButtonDisabled(true);
                }
            })
            .catch(error => {
                setAlertMessage(`Ошибка при регистрации: ${error}`);
                setShowAlert(true);
                setCountdown(60);
                setIsButtonDisabled(true);
            })
            .finally(() => setIsLoading(false));
    };
    
    return (
        <Modal className='modal_container' show={showModal} onHide={closeModal}>
            <Modal.Body className='modal_body'>
                <Modal.Title className='modal_title'>Регистрация</Modal.Title>
                {showAlert && (
                    <Alert
                        variant={isError ? "danger" : "success"}
                        onClose={() => setShowAlert(false)}
                        dismissible>
                        {alertMessage}
                    </Alert>
                )}
                <Form
                    noValidate
                    className='modal_form'
                    validated={validated}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleSignup(event);
                        }
                    }}
                    onSubmit={handleSignup}
                >
                    <Form.Group className='modal_form_group' controlId='formRegNickname'>
                        <Form.Control.Feedback type='invalid'>
                            Введите корректный никнэйм
                        </Form.Control.Feedback>
                        <Form.Control
                            type='text'
                            name='userName'
                            value={formData.userName}
                            onChange={handleInputChange}
                            placeholder='Никнэйм'
                            required
                            maxLength={20}
                        />
                    </Form.Group>
                    <Form.Group className='modal_form_group' controlId='formRegEmail'>
                        <Form.Control
                            type='email'
                            name='email'
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder='Электронная почта'
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Введите корректную электронную почту
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='modal_form_group' controlId='formRegPassword'>
                        <Form.Control
                            type='password'
                            name='password'
                            value={formData.password}
                            onChange={handleInputChange}
                            placeholder='Пароль'
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Введите пароль
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='modal_form_group' controlId='formRegPasswordCheck'>
                        <Form.Control
                            type='password'
                            name='confirmPassword'
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            placeholder='Подтверждение пароля'
                            required
                            isInvalid={!passwordsMatch}
                        />
                    </Form.Group>
                    {/* <Form.Group className='modal_form_group' controlId='formRegCheckBox'>
                    <Form.Check
                        type='checkbox'
                        name='stayLoggedIn'
                        label='Оставаться в аккаунте'
                    />
                </Form.Group> */}
                </Form>
                
                
                {countdown > 0 && (
                    <div className='regButtonTimer'>
                        {isButtonDisabled && `Попробуйте снова через: ${countdown} сек.`}
                    </div>
                )}
                
                <ButtonWithLoader
                    isLoading={isLoading}
                    disabled={isLoading || isButtonDisabled}
                    onClick={handleSignup}
                >
                    ЗАРЕГИСТРИРОВАТЬСЯ
                </ButtonWithLoader>
            </Modal.Body>
        </Modal>
    );
};

export default RegModalWindow;