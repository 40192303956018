import React, { useEffect, useState } from 'react';
import './TeamManage.css';
import { Form, Pagination } from 'react-bootstrap';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import { TournamentConstants } from '../../../../helpers/constants/tournamentConstants';
import { getAllTeamsByGameTypeId } from '../../../../http/adminActionsAPI';
import CustomSpinner1 from '../../../../components/CustomSpinners/CustomSpinner1';
import { ICommonTeamModel } from '../../../../models/dto/team/ICommonTeamModel';

const TeamManage: React.FC = () => {
    const [gameTypeId, setGameTypeId] = useState(TournamentConstants.CS2.gameTypeId);
    const [sortType, setSortType] = useState('nearest');
    const [fetchingteamInfo, setFetchingteamInfo] = useState(true);
    const [teamList, setTeamList] = useState<ICommonTeamModel[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const teamsPerPage = 6;

    useEffect(() => {
        if (gameTypeId) {
            setFetchingteamInfo(true);
            getAllTeamsByGameTypeId(gameTypeId)
                .then(data => {
                    if (data) {
                        setTeamList(data);
                    }
                })
                .finally(() => setFetchingteamInfo(false));
        }
    }, [gameTypeId])

    const handleGameTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setGameTypeId(event.target.value);
    };

    const handleSortTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortType(event.target.value);
    };

    const sortTeams = (teams: ICommonTeamModel[]) => {
        switch (sortType) {
            case 'nearest':
                return teams.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
            default:
                return teams;
        }
    };

    const teamsToDisplay = sortTeams(teamList);

    const indexOfLastTeam = currentPage * teamsPerPage;
    const indexOfFirstTeam = indexOfLastTeam - teamsPerPage;
    const currentTeams = teamsToDisplay.slice(indexOfFirstTeam, indexOfLastTeam);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    return (
        <div className='tournaments_manage_tab_container'>
            <h4>Управление командами</h4>
            <div className='current_tournaments_unit'>
                <div className='current_tournaments_unit_table'>
                    <div className='all_tourn_header'>Все команды</div>
                    <div className='tournaments_manage_filter_container'>
                        <Form.Select
                            className="tournaments_manage_filter"
                            name="sortType"
                            value={sortType}
                            required
                            onChange={handleSortTypeChange}
                        >
                            <option value="nearest">Недавно созданные</option>
                        </Form.Select>
                        <Form.Select
                            className="tournaments_manage_filter"
                            name="gameTypeId"
                            value={gameTypeId}
                            required
                            onChange={handleGameTypeChange}
                        >
                            <option value={TournamentConstants.CS2.gameTypeId}>CS2</option>
                            <option value={TournamentConstants.DOTA2.gameTypeId}>DOTA2</option>
                            <option value={TournamentConstants.FORTNITE.gameTypeId}>Fortnite</option>
                        </Form.Select>
                    </div>
                    {fetchingteamInfo ? (
                        <CustomSpinner1 />
                    ) : (
                        <table className='current_tournaments_table'>
                            <thead>
                                <tr>
                                    <th className='current_tournaments_table_header name'>Название</th>
                                    <th className='current_tournaments_table_header'>Дата создания</th>
                                    <th className='current_tournaments_table_header name'>Игроки</th>
                                    <th className='current_tournaments_table_header name'>Редактировать</th>
                                    <th className='current_tournaments_table_header'>Удалить</th>
                                </tr>
                            </thead>
                            <tbody className='current_tournaments_table_body'>
                                {currentTeams.map(team => (
                                    <tr key={team.gameTypeId}>
                                        <td className='current_tournaments_table_td name' onClick={() => { window.open(`/team/${team.teamId}`, '_blank') }}>
                                            {team.name}
                                        </td>
                                        <td className='current_tournaments_table_td'>{new Date(team.creationDate).toLocaleDateString()}</td>
                                        
                                        <td className='current_tournaments_table_td'>
                                            {team.players?.map(player => (
                                                <div
                                                    key={player.playerId}
                                                    onClick={() => window.open(`/player/${player.playerId}`, '_blank')}
                                                    className='current_tournaments_player'
                                                >
                                                    {player.login}
                                                </div>
                                            ))}
                                        </td>
                                        <td className='current_tournaments_table_td'>
                                            <PencilFill
                                                className='general_icon'
                                                onClick={() => { window.open(`/update-team/${team.teamId}`, '_blank') }}
                                            />
                                        </td>
                                        <td className='current_tournaments_table_td'>
                                            <TrashFill className='general_icon'/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className='current_tournaments_pagination'>
                    <Pagination>
                        {Array.from({ length: Math.ceil(teamsToDisplay.length / teamsPerPage) }, (_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </div>
            </div >
        </div >
    );
};

export default TeamManage;