import './StatisticsIsNotFound.css';

interface StatisticsIsNotFoundProps {
    children: React.ReactNode;
    disabled: boolean;
    text?: string;
}

const StatisticsIsNotFound: React.FC<StatisticsIsNotFoundProps> = ({ children, disabled, text }) => {
    return (
        <>
            {disabled ? (
                <div className={`StatisticsIsNotFound_container`}>
                    <div className="StatisticsIsNotFound_blur">
                        {children}
                    </div>
                    <div className="StatisticsIsNotFound_title">{text ? text : 'Нет статистики'}</div>
                </div>
            ) : (
                <>
                    {children}
                </>
            )}
        </>
)};

export default StatisticsIsNotFound;