export enum TournamentTypeEnum {
    OnlyRobin = 0, //только круговая
    OnlySingle = 1, //только стандартная + single elimination
    OnlyDouble = 2, // только стандартная + double elimination
    RobinWithSingle = 3, //круговая + single elimination
    RobinWithDouble = 4, //круговая + double elimination
}

const TournamentTypeMapping: { [key: string]: string } = {
    "OnlyRobin": "Круговая",
    "OnlySingle": "Single",
    "OnlyDouble": "Double",
    "RobinWithSingle": "Круговая + Single",
    "RobinWithDouble": "Круговая + Double",
};

export default TournamentTypeMapping;