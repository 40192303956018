import { useState, useEffect } from 'react';
import './User.css';
import { Container } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { getPLayerInfo } from '../../http/userAPI';
import { useParams } from 'react-router-dom';
import { IPlayerPageModel } from '../../models/dto/player/IPlayerPageModel';
import UserHeader from '../../components/UserHeader/UserHeader';
import InDevelop from '../../components/InDevelop/InDevelop';
import CustomSpinner1 from '../../components/CustomSpinners/CustomSpinner1';
import DOTA2PlayerProfile from '../../components/DOTA2/DOTA2PlayerProfile/DOTA2PlayerProfile';
import CS2UserProfile from '../../components/CS2/CS2UserProfile/CS2UserProfile';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';
import { ITeamStoreModel } from '../../models/dto/team/ITeamStoreModel';

const User: React.FC = observer(() => {
    const [playerData, setPlayerData] = useState<IPlayerPageModel>();
    const { playerId } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [activeTab, setActiveTab] = useState<string>('f9c18834-95f0-49ee-9de9-6116bcd08161')
    
    useEffect(() => {
        if (playerId) {
            setIsLoading(true); 
            
            getPLayerInfo(playerId)
                .then((data) => {
                    if (data) {
                        setPlayerData(data);
                    }
                })
                .catch((error) => {
                    console.error('Fetch Player Data error:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [playerId]);
    
    function playerInTeam(teamId: string) : Boolean {
        playerData?.playerTeams?.forEach((team) => {
            if (teamId === team.teamId) { return true; }
        });
        return false;
    }
    
    function getTeamByGameTypeId(gameTypeId: string) : ITeamStoreModel | undefined {
        return playerData?.playerTeams?.find((team) => team.gameTypeId === gameTypeId);
    }
    
    return (
        <Container>
            <div className='user_general_container'>
                <div className='user_first_container'>
                    <div className='userCard_mainContainer'>
                        {isLoading ? (
                            <div className='user_spinnerContiner'>
                                <CustomSpinner1 sizeSpinnerContainer='100px' size='100px' />
                            </div>
                        ) : (
                            <UserHeader playerId={playerData?.playerId || null} editMode={true} />
                        )}
                    </div>
                    
                    <div className={`userCard_secondContainer`}>
                        {isLoading ? (
                            <div className='user_spinnerContiner'>
                                <CustomSpinner1 sizeSpinnerContainer='100px' size='100px' />
                            </div>
                        ) : (
                            <>
                                <InDevelop>
                                    <div className='userCard_secondContainer_header'>
                                        <div className='userCard_secondContainer_headerLevl'>ТЕКУЩИЙ УРОВЕНЬ: <span>20</span></div>
                                    </div>
                                    
                                    <div className='userCard_secondContainer_center'>
                                        <div className='userCard_secondContainer_centerCircleContainer'>
                                            <div className='userCard_secondContainer_centerCircle'>
                                                100%
                                            </div>
                                        </div>
                                        <div className='userCard_secondContainer_playerTasksContainer'>
                                            <div className='userCard_secondContainer_playerTasksTitle'>ЗАДАНИЯ ДНЯ:</div>
                                            <div className='userCard_secondContainer_playerTasks'>
                                                <div className='userCard_secondContainer_playerTask'>
                                                    <span>ПОБЕДИТЬ 2 ИГРЫ 2X2</span> <div className='userCard_secondContainer_playerTaskCircle done'></div>
                                                </div>
                                                
                                                <div className='userCard_secondContainer_playerTask'>
                                                    <span>СТАТЬ MVP 5 РАЗ ПОДРЯД</span> <div className='userCard_secondContainer_playerTaskCircle done'></div>
                                                </div>
                                                
                                                <div className='userCard_secondContainer_playerTask'>
                                                    <span>ЗАЛОЖИТЬ БОМБУ 3 РАЗА</span> <div className='userCard_secondContainer_playerTaskCircle done'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </InDevelop>
                            </>
                        )}
                    </div>
                </div>
                
                <div className='user_playerGamesProfiles_tabs'>
                    {/* {playerData?.availableGameTypeIds?.map((g, idx) => (
                        <div 
                            className={`user_playerGamesProfiles_tab ${activeTab === g.gameId ? 'active' : ''}`} 
                            key={idx}
                            onClick={() => setActiveTab(g.gameTitle)}
                        >
                            {g.gameTitle}
                        </div>
                    ))} */}
                    
                    <button 
                        className={`user_playerGamesProfiles_tab ${activeTab === TournamentConstants.CS2.gameTypeId ? 'active' : ''}`} 
                        onClick={() => setActiveTab(TournamentConstants.CS2.gameTypeId)}
                    >
                        CS 2
                    </button>
                    
                    <button 
                        className={`user_playerGamesProfiles_tab ${activeTab === TournamentConstants.DOTA2.gameTypeId ? 'active' : ''}`} 
                        onClick={() => setActiveTab(TournamentConstants.DOTA2.gameTypeId)}
                    >
                        DOTA 2
                    </button>
                </div>
                
                {activeTab === TournamentConstants.CS2.gameTypeId ? (
                    <>
                        {!isLoading && (<CS2UserProfile playerId={playerData?.playerId || ''} currentTeamInfo={getTeamByGameTypeId(TournamentConstants.CS2.gameTypeId)} />)}
                    </>
                ) : activeTab === TournamentConstants.DOTA2.gameTypeId ? (
                    <>
                        {!isLoading && (<DOTA2PlayerProfile playerId={playerData?.playerId || ''} currentTeamInfo={getTeamByGameTypeId(TournamentConstants.DOTA2.gameTypeId)} />)}
                    </>
                ) : (
                    <>
                    
                    </>
                )}
            </div>
        </Container >
    );
});

export default User;