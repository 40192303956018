import React, { useContext } from 'react';
import { AppContext } from '../../components/AppContext';
import TeamCard from '../../components/TeamCard/TeamCard';
import { Container } from 'react-bootstrap';

const UserTeams: React.FC = () => {
    const { player } = useContext(AppContext);

    return (
        <Container className='user_general_container'>
            <h2>Мои Команды</h2>
            {player?.playerTeams?.map(team => (
                <TeamCard currentTeamInfo={team} isManagePage={true} />
            ))}
        </Container>
    );
};

export default UserTeams;