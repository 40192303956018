import { FloatingLabel, Form, Tab } from "react-bootstrap";
import { IDota2CreateTournamentViewModel } from "../../viewModels/tournament/Dota2CreateTournamentViewModel";
import { LobbyVisibilityEnum } from "../../models/enums/dota2/LobbyVisibilityEnum";
import { GameModeEnum } from "../../models/enums/dota2/GameModeEnum";
import { LobbyPauseSettingsEnum } from "../../models/enums/dota2/LobbyPauseSettingsEnum";
import { LobbyDotaTVDelayEnum } from "../../models/enums/dota2/LobbyDotaTVDelayEnum";

interface DotaSettingsFormProps {
    dota2Tournament: IDota2CreateTournamentViewModel;
    handleDota2TournamentVMChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Dota2TabCreateTournament: React.FC<DotaSettingsFormProps> = ({ dota2Tournament, handleDota2TournamentVMChange }) => {
    return (
        <Tab.Pane eventKey="fourth">
            <Form className="create_tournament_form">
                <FloatingLabel label="ВИДИМОСТЬ ЛОББИ" className='create_tourn_label'>
                    <Form.Select
                        className="create_tournament_form_control option"
                        name="lobbyVisibility"
                        value={dota2Tournament.lobbyVisibility || ''}
                        required
                        onChange={handleDota2TournamentVMChange}
                    >
                        <option value={LobbyVisibilityEnum.Public}>Для всех</option>
                        <option value={LobbyVisibilityEnum.Friends}>Только для друзей</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel label="ИГРОВОЙ РЕЖИМ" className='create_tourn_label'>
                    <Form.Select
                        className="create_tournament_form_control option"
                        name="gameMode"
                        onChange={handleDota2TournamentVMChange}
                        value={dota2Tournament.gameMode || ''}
                    >
                        <option value={GameModeEnum.CM}>Capitains Mode</option>
                        <option value={GameModeEnum.AM}>All Pick</option>
                        <option value={GameModeEnum.MID1V1}>MID 1vs1</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel label="РЕЖИМ ИГРОВЫХ ПАУЗ" className='create_tourn_label'>
                    <Form.Select
                        className="create_tournament_form_control option"
                        name="lobbyPauseSettings"
                        value={dota2Tournament.lobbyPauseSettings || ''}
                        required
                        onChange={handleDota2TournamentVMChange}
                    >
                        <option value={LobbyPauseSettingsEnum.Unlimited}>Без ограничений</option>
                        <option value={LobbyPauseSettingsEnum.Limited}>Лимитированы</option>
                        <option value={LobbyPauseSettingsEnum.Disabled}>Запрещены</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel label="ЗАДЕРЖКА DotaTV (сек.)" className='create_tourn_label'>
                    <Form.Select
                        className="create_tournament_form_control option"
                        name="lobbyDotaTVDelay"
                        value={dota2Tournament.lobbyDotaTVDelay || ''}
                        required
                        onChange={handleDota2TournamentVMChange}
                    >
                        <option value={LobbyDotaTVDelayEnum.LobbyDotaTV_10}>10</option>
                        <option value={LobbyDotaTVDelayEnum.LobbyDotaTV_120}>120</option>
                        <option value={LobbyDotaTVDelayEnum.LobbyDotaTV_300}>300</option>
                        <option value={LobbyDotaTVDelayEnum.LobbyDotaTV_900}>900</option>
                    </Form.Select>
                </FloatingLabel>
            </Form>
        </Tab.Pane>
    );
};

export default Dota2TabCreateTournament;