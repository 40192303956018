import React from 'react';
import Lottie from 'react-lottie';
import './SoonEmpty.css';
import animationData from '../../assets/animations/soonEmpty_animation.json';

interface SoonEmptyProps {
    text?: string
}

const SoonEmpty: React.FC<SoonEmptyProps> = ({ text }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='soonEmpty_container'>
            <div className="soonEmpty">
                <Lottie
                    options={defaultOptions}
                    height={400}
                    width={400} 
                />
                <div className={`soonEmpty_text ${text ? 'propsText' : ''}`}>
                    {text ? (
                        text
                    ) : (
                        'В РАЗРАБОТКЕ...'
                    )}
                </div>
            </div>
        </div>
    );
};

export default SoonEmpty;