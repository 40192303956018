import { authInstance } from "..";
import { ICS2MapModel } from "../../models/dto/cs2/ICS2MapModel";

export const createCS2Tournament = async (
    tournamentData: FormData
): Promise<string | false> => {
    try {
        const response = await authInstance.post('CS2Tournaments/CreateTournament', tournamentData);
        const { tournamentId } = response.data;
        if (response.status === 200 && tournamentId) {
            return tournamentId;
        } else {
            console.log('Unexpected response or missing tournamentId');
            return false;
        }
    } catch (e: any) {
        console.log(e.response?.data?.message || 'Error creating tournament');
        return false;
    }
};

export const updateCS2Tournament = async (
    tournamentData: FormData,
    tournamentId: string
): Promise<string | false> => {
    try {
        const response = await authInstance.put(`CS2Tournaments/UpdateTournament/${tournamentId}`, tournamentData);
        return response.data;
    } catch (e: any) {
        console.error(e.response?.data?.message || 'Error creating tournament', e);
        return false;
    }
};

export const getAllMaps = async (): Promise<ICS2MapModel[] | false> => {
    try {
        const response = await authInstance.get(`CS2Tournaments/GetAllMaps`);
        return response.data as ICS2MapModel[];
    } catch (e: any) {
        alert(e.response?.data?.message || 'Error when getting maps!');
        return false;
    }
};

export const getTournamentMaps = async (
    tournamentId: string
): Promise<ICS2MapModel[] | false> => {
    try {
        const response = await authInstance.get(`CS2Tournaments/GetTournamentMaps?tournamentId=${tournamentId}`);
        return response.data as ICS2MapModel[];
    } catch (e: any) {
        alert(e.response?.data?.message || 'Error when getting maps!');
        return false;
    }
};