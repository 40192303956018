import { HubConnection } from '@microsoft/signalr';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ICS2LobbyState } from '../../models/dto/lobby/CS2/ICS2LobbyState';
import { ICS2LobbySettings } from '../../models/dto/lobby/CS2/ICS2LobbySettings';
import { AppContext } from '../AppContext';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import CustomSpinner1 from '../CustomSpinners/CustomSpinner1';
import { CheckLg, Clipboard } from 'react-bootstrap-icons';
import { Modal } from 'react-bootstrap';
import '../../pages/MapSelection/MapSelection.css';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';

const CS2Lobby: React.FC<{ lobbyState: ICS2LobbyState, connection: HubConnection, lobbySettings: ICS2LobbySettings }> = observer(({ lobbyState, connection, lobbySettings }) => {
    const { matchLobbyId } = useParams<{ matchLobbyId: string }>();
    const { player } = useContext(AppContext);
    const [isCopied, setIsCopied] = useState(false);
    const playerOrCaptain = player?.playerId === player?.getTeamByGameTypeId(TournamentConstants.CS2.gameTypeId)?.captainId ? 'captain' : 'player';
    const [showServerModal, setShowServerModal] = useState<boolean>(false);
    const { isVisible: showServerLoadingModal, openModal: openServerLoadingModal, closeModal: closeServerLoadingModal } = useModal();

    const connectionRef = useRef<HubConnection | null>(null);
    const lobbyStateRef = useRef<ICS2LobbyState | undefined>(undefined);
    const [banTimer, setBanTimer] = useState(0);
    const isTeamABanning = lobbyState?.teamA.players.some(player => player.memberId === lobbyStateRef.current?.baningCaptainId);
    const [notBeginningBans, setNotBeginningBans] = useState(false);
    const banningTeamName = isTeamABanning ? lobbyState?.teamA.teamName : lobbyState?.teamB.teamName;


    const handleMapSelection = async (mapId: string) => {
        if (player?.playerId === lobbyStateRef.current?.baningCaptainId
            && connectionRef.current
            && (lobbyStateRef.current?.selectedMapNames?.length || 0) === 0) {
            await connectionRef.current.invoke("BanMap", matchLobbyId, mapId);
        }

        if (player?.playerId === lobbyStateRef.current?.baningCaptainId
            && connectionRef.current
            && (lobbyStateRef.current?.selectedMapNames?.length || 0) <= 1) {
            setNotBeginningBans(true);
        }
    };

    const closeServerLoading = () => {
        setShowServerModal(false);
        closeServerLoadingModal();
    }

    const handleCopy = async () => {
        const copyText = `connect ${lobbyState?.serverIp}:${lobbyState?.serverPort}; password ${lobbyState?.serverPassword}`;
        if (navigator?.clipboard) {
            await navigator.clipboard.writeText(copyText);
        }
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 5000);
    };

    useEffect(() => {
        connectionRef.current = connection;
        lobbyStateRef.current = lobbyState;
    }, [connection, lobbySettings, lobbyState]);

    useEffect(() => {
        if (lobbyState?.serverIp && lobbyState?.serverPort && lobbyState?.serverPassword && showServerModal) {
            openServerLoadingModal();
        }
    }, [lobbyState, showServerModal])

    useEffect(() => {
        if (connection) {

            connection.on("UpdateTimer", (timeLeft: number) => {
                setBanTimer(timeLeft);
            });

            connection.on("TimerStageExpired", async (lobbyId: string) => {

            });

            connection.on("AllTimerStagesExpired", async (lobbyId: string) => {
                setBanTimer(0);
                openServerLoadingModal();
                setShowServerModal(true);
            });

            return () => {
                connection.off("UpdateTimer");
                connection.off("SetLobbySettings");
                connection.off("AllTimerStagesExpired");
                connection.off("TimerStageExpired");
            };
        }
    }, [connection]);

    return (
        <>
            <div className='maps_container'>
                {
                    lobbyState?.banTimerVisible && (
                        <>
                            <div className='maps_title_container'>
                                <h3>СТАДИЯ БАНОВ</h3>
                                {
                                    banTimer !== 0 &&
                                    <>
                                        <div className='maps_title_chooser'>
                                            Банит капитан команды <br /><span>{banningTeamName}</span>
                                        </div>
                                        <div className="maps_ban_banTimer">
                                            Оставшееся время для выбора: <span>{banTimer}</span> сек.
                                        </div>
                                    </>
                                }

                                {!showServerLoadingModal && (
                                    <button className='serverModalButton' onClick={() => openServerLoadingModal()}>Строка подключения</button>
                                )}
                            </div>
                            <div className='maps'>
                                {
                                    lobbySettings?.cs2Maps.map((map) => (
                                        <div
                                            key={map.mapId}
                                            className={`map ${lobbyState?.bannedMapIds?.includes(map.mapId) ? 'pick' : (banTimer <= 0 && notBeginningBans) ? 'game' : playerOrCaptain}`}
                                            style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_STORAGE_URL}${map.mapImgUrl})` }}
                                            onClick={() => (player?.playerId === player?.getTeamByGameTypeId(TournamentConstants.CS2.gameTypeId)?.captainId || false) && !lobbyState?.bannedMapIds?.includes(map.mapId) && handleMapSelection(map.mapId)}
                                        >
                                            <h3>{map.name}</h3>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
                }
            </div>
            <Modal className='serverReady_modal' show={showServerLoadingModal} size='lg' onHide={closeServerLoading}>
                <Modal.Body className='serverReady_modal_body'>
                    <Modal.Title className='serverReady_modal_title'>
                        {lobbyStateRef.current?.serverIp && lobbyStateRef.current?.serverPort && lobbyStateRef.current?.serverPassword ? 'Информация о подключении к серверу' : 'Ожидание ответа от сервера'}
                    </Modal.Title>
                    <hr></hr>
                    <div className='serverReady_modal_mainInfo'>
                        {
                            lobbyState?.serverIp && lobbyState?.serverPort && lobbyState?.serverPassword ? (
                                <div className='server_ready_container'>
                                    <div className='server_info'>
                                        <div>СТРОКА ПОДКЛЮЧЕНИЯ</div>
                                        <div className='server_connect_address'>
                                            connect {lobbyState?.serverIp}:{lobbyState?.serverPort}; password {lobbyState?.serverPassword}
                                            {isCopied ? <CheckLg /> : <Clipboard onClick={handleCopy} className='clipboard_icon' />}
                                        </div>
                                    </div>
                                    <a href={`steam://connect/${lobbyState?.serverIp}:${lobbyState?.serverPort}/${lobbyState?.serverPassword}`} className='lobby_connect_button'>Подключиться к игре</a>
                                </div>
                            ) : (
                                <div className='server_not_ready_container'>
                                    <div>Запустите Counter-Strike 2, если вы этого еще не сделали</div>
                                    <CustomSpinner1 size={`100`} sizeSpinnerContainer='120px' />
                                </div>
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
});

export default CS2Lobby;
