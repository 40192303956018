import { action, makeObservable, observable } from 'mobx'; 
import { IPlayerStoreModel } from '../models/dto/player/IPlayerStoreModel'; 
import { userRoles } from '../helpers/constants/userRoles';
import User from './User';
import { ITeamStoreModel } from '../models/dto/team/ITeamStoreModel';

class PlayerStore extends User {
    playerId: string | null = null;
    playerStatId: string | null = null;
    statusId?: string | null = null;
    
    steamID: string | null = null;
    name: string | null = null;
    surname: string | null = null;
    middlename?: string | null = null;
    avatarURL?: string | null = null;
    skills?: string | null = null;
    preferredPositions?: string | null = null;
    notes?: string | null = null;
    birthDate: string | null = null;
    gender: boolean | null = null;
    personalScore: number | null = null;

    playerTeams: ITeamStoreModel[] | null = null;
    
    constructor() {
        super();
        makeObservable(this, {
            playerId: observable,
            playerStatId: observable,
            statusId: observable,

            steamID: observable,
            name: observable,
            surname: observable,
            middlename: observable,
            avatarURL: observable,
            skills: observable,
            preferredPositions: observable,
            notes: observable,
            birthDate: observable,
            gender: observable,
            personalScore: observable,

            playerTeams: observable,

            loginPlayer: action,
            logout: action,
        });
    }
    
    loginPlayer(playerData: IPlayerStoreModel): void {
        this.userId = playerData.userId;
        this.playerId = playerData.playerId;
        this.statusId = playerData.statusId;
        this.playerStatId = playerData.playerStatId;
        
        this.isAuth = true;
        this.isAdmin = playerData.roles?.includes(userRoles.admin) ?? false; 

        this.email = playerData.email;
        this.login = playerData.login; 

        this.steamID = playerData.steamID;
        this.name = playerData.name;
        this.surname = playerData.surname;
        this.middlename = playerData.middlename;
        this.avatarURL = playerData.avatarURL;
        this.skills = playerData.skills;
        this.preferredPositions = playerData.preferredPositions;
        this.notes = playerData.notes;
        this.birthDate = playerData.birthDate;
        this.gender = playerData.gender;
        this.personalScore = playerData.personalScore;

        this.playerTeams = playerData.playerTeams;
    }

    logout(): void {
        this.userId = null;
        this.playerId = null;
        this.playerStatId = null;
        this.statusId = null;

        this.isAuth = false;
        this.isAdmin = false;

        this.email = null;
        this.login = null;
        
        this.steamID = null;
        this.name = null;
        this.surname = null;
        this.middlename = null;
        this.avatarURL = null;
        this.skills = null;
        this.preferredPositions = null;
        this.notes = null;
        this.birthDate = null;
        this.gender = null;
        this.personalScore = null;

        this.playerTeams = null;
    }

    public playerInTeam(teamId: string) : Boolean {
        this.playerTeams?.forEach((team) => {
            if (teamId === team.teamId) { return true; }
        });
        return false;
    }

    public getTeamByGameTypeId(gameTypeId: string) : ITeamStoreModel | undefined {
        return this.playerTeams?.find((team) => team.gameTypeId === gameTypeId);
    }
}

export default PlayerStore;