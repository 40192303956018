import React, { useEffect, useState } from 'react';
import { Match, Participant } from '../../../Inerfaces';
import { CommonConstants } from '../../Constants';
import { Id, StageType } from '../../../Types';
import './BracketMatch.css'
import SteppedLineTo from '../SteppedLine';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { editMatchDate } from '../../../../../http/adminActionsAPI';
import { CheckCircle } from 'react-bootstrap-icons';

interface RenderMatchProps {
    match: Match;
    participantMap: Map<Id, Participant>;
    selectedOpponent: Id | null;
    isEditing: boolean;
    handleEditStart: (matchId: string | number) => void;
    handleMouseEnter: (id: Id) => void;
    handleMouseLeave: () => void;
    handleDragStart: (event: React.DragEvent<HTMLDivElement>, id: Id | null, position: number | null, roundId: Id) => void;
    handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
    handleDrop: (event: React.DragEvent<HTMLDivElement>, targetId: Id | null, targetPosition: number | null, targetRoundId: Id) => void; // Добавлен targetRoundId;
    bracketType: StageType;
    matches: Match[];
    roundName: string | undefined;
    playerTeamId: string;
}

const BracketMatch: React.FC<RenderMatchProps> = ({
    match,
    participantMap,
    selectedOpponent,
    isEditing,
    handleEditStart,
    handleMouseEnter,
    handleMouseLeave,
    handleDragStart,
    handleDragOver,
    handleDrop,
    bracketType,
    matches,
    roundName,
    playerTeamId
}) => {
    const navigate = useNavigate();
    const [editedDate, setEditedDate] = useState<string>('');
    const [isMatchDateEdit, setMatchDateEdit] = useState<boolean>(false);
    
    useEffect(() => {;
        setEditedDate(match?.date || ''); 
    }, [match]);
    
    let matchOpponent1Id = match.opponent1 ? match.opponent1.id : null;
    let matchOpponent1Position = match.opponent1 ? match.opponent1.position : null;
    
    let matchOpponent2Id = match.opponent2 ? match.opponent2.id : null;
    let matchOpponent2Position = match.opponent2 ? match.opponent2.position : null;
    
    let matchId = match.id;
    
    let eliminationOrRobinStyle = bracketType === 'single_elimination' || bracketType === 'double_elimination' ? 'brackets' : 'bracketsRobin';
    
    // Получаем оппонентов, проверяя, что они не null
    let opponent1 = (matchOpponent1Id !== null && match.opponent1) ? participantMap.get(matchOpponent1Id) : null;
    let opponent2 = (matchOpponent2Id !== null && match.opponent2) ? participantMap.get(matchOpponent2Id) : null;
    
    const shortMatchName = () => {
        let shortRoundName =
            bracketType === 'double_elimination' 
            ?
                roundName === 'Финал' ? 'Final' :
                roundName === 'Полуфинал' ? `Semi Final ${match.match_type === 1 ? `${match.number}` : ''}` :
                roundName === 'За 3 место' ? 'Consolation Final' :
                roundName === 'Гранд финал' ? 'Grand Final' :
                `R${roundName} M${match.number}` :
            bracketType === 'single_elimination'
            ?
                roundName === 'Финал' ? 'Final' :
                roundName === 'Полуфинал' ? `Semi Final ${match.number}` :
                match.match_type === 3 ? 'Consolation Final' :
                roundName === 'Гранд финал' ? 'Grand Final' :
                `R${roundName} M${match.number}`
            :
            `Round ${roundName} M${match.number}`;
        
        return (
            bracketType === 'double_elimination' && match.match_type === 1 ? `WB ${shortRoundName}` : 
            bracketType === 'double_elimination' && match.match_type === 4 ? `${shortRoundName}` : 
            bracketType === 'single_elimination' || bracketType === 'round_robin' ? `${shortRoundName}` :
            `LB ${shortRoundName}`
        );
    }
    
    let opponent1ClassName = `${eliminationOrRobinStyle}_opponent`; // Имя класса для оппонента 1
    let opponent1ResultClassName =  // Имя класса результата для оппонента 1
        !match.opponent1 || match.opponent1.id === CommonConstants.GuidEmpty ? `${eliminationOrRobinStyle}_default_score` :
        !match.opponent2 || match.opponent2.id === CommonConstants.GuidEmpty ? `${eliminationOrRobinStyle}_win_score` :
        `${eliminationOrRobinStyle}_${match.opponent1.result || 'default'}_score`;
    
    let opponent2ClassName = `${eliminationOrRobinStyle}_opponent ${(opponent2 && selectedOpponent === opponent2.id) || (playerTeamId === opponent2?.id) ? 'selected' : ''}`; // Имя класса для оппонента 2
    let opponent2ResultClassName = // Имя класса результата для оппонента 2
        !match.opponent2 || match.opponent2.id === CommonConstants.GuidEmpty ? `${eliminationOrRobinStyle}_default_score` :
        !match.opponent1 || match.opponent1.id === CommonConstants.GuidEmpty ? `${eliminationOrRobinStyle}_win_score` :
        `${eliminationOrRobinStyle}_${match.opponent2.result || 'default'}_score`; 
    
    let nextMatch = matches.find(nextMatch => nextMatch.id === match.match_for_winner);
    
    let isLineToNextMatch: boolean = 
        match.match_type === 3 ? false :
        nextMatch && nextMatch?.match_type === 4 ? true :
        nextMatch && nextMatch?.group_id === match.group_id ? true : 
        false;
    
    let matchClassName = `${eliminationOrRobinStyle}_match match_${matchId} ${selectedOpponent && (matchOpponent1Id === selectedOpponent || matchOpponent2Id === selectedOpponent) ? 'highlighted' : ''}`;
    let matchNumberClassName = `${eliminationOrRobinStyle}_matchNumber ${selectedOpponent && (matchOpponent1Id === selectedOpponent || matchOpponent2Id === selectedOpponent) ? 'highlighted' : ''}`;
    
    const handleSaveDate = async (matchId: string, editedDate: string) => {
        if (!editedDate) {
            alert('Дата не выбрана');
            return;
        }
        
        const utcDate = new Date(editedDate).toISOString();
        
        try {
            const result = await editMatchDate(matchId, utcDate);
            
            if (result === true) {
                setMatchDateEdit(false);
            } else {
                alert(`Ошибка сохранения новой даты: ${result}`);
            }
        } catch (e) {
            console.log(e);
        }
    };
    
    return (
        <div className="match_container" key={match.id}>
            <div className={matchClassName} key={match.id} onClick={() =>  handleEditStart(matchId)}>
                <div className={`${matchNumberClassName}`}>{shortMatchName()}</div>
                <div className={`${eliminationOrRobinStyle}_opponents`}>
                    <div
                        className={opponent1ClassName}
                        onMouseEnter={() => matchOpponent1Id !== null && handleMouseEnter(matchOpponent1Id)} // Отслеживание курсора на блоке
                        onMouseLeave={handleMouseLeave} // Отслеживание схода курсора с блока
                        draggable={isEditing}  // Вкл./Выкл. режим редактирования
                        onDragStart={(e) => handleDragStart(e, matchOpponent1Id, matchOpponent1Position, match.round_id)} // Отслеживание перетаскивания оппонента
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, matchOpponent1Id, matchOpponent1Position, match.round_id)}
                    >
                        <div
                            onClick={(e) => {
                                if (!opponent1) {
                                    e.preventDefault();
                                } else {
                                    navigate(`/team/${opponent1.id}`);
                                }
                            }}
                        >
                            <div
                                className={`opponentName ${((opponent1 && selectedOpponent === opponent1.id)) ? 'selected' : ''} ${playerTeamId === opponent1?.id ? 'playerTeam' : ''}`}
                                title={opponent1 && opponent1.id !== null && opponent1.id !== CommonConstants.GuidEmpty ? opponent1.name : ''}
                            >
                                {
                                    match.opponent1?.id === CommonConstants.GuidEmpty ? 'BYE' :
                                    match.opponent1?.id === null ? 'TBD' :
                                    opponent1?.tag
                                }
                            </div>
                        </div>
                        
                        <b className={opponent1ResultClassName}>
                            {
                                !match.opponent1 || match.opponent1.id === CommonConstants.GuidEmpty ? '-' :
                                !match.opponent2 || match.opponent2.id === CommonConstants.GuidEmpty ? 'W' :
                                !match.opponent1.score && match.opponent1.result === 'win' ? 'W' :
                                !match.opponent1.score && match.opponent1.score !== 0 && match.opponent1.result === 'loss' ? 'L' :
                                !match.opponent1.score && match.opponent1.score !== 0 && match.opponent1.result === 'draw' ? 'D' :
                                !match.opponent1.score && match.opponent2.score && match.opponent1.result === 'loss' ? 0 :
                                match.opponent1.score ?? '-'
                            }
                        </b>
                    </div>
                    
                    <div
                        className={opponent2ClassName}
                        onMouseEnter={() => matchOpponent2Id !== null && handleMouseEnter(matchOpponent2Id)} // Отслеживание курсора на мыши
                        onMouseLeave={handleMouseLeave}
                        draggable={isEditing} // Вкл./Выкл. режим редактирования
                        onDragStart={(e) => matchOpponent2Id !== null && handleDragStart(e, matchOpponent2Id, matchOpponent2Position, match.round_id)} // Отслеживание перетаскивания оппонента
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, matchOpponent2Id, matchOpponent2Position, match.round_id)}
                    > 
                        <div
                            onClick={(e) => {
                                if (!opponent2) {
                                    e.preventDefault();
                                } else {
                                    navigate(`/team/${opponent2.id}`);
                                }
                            }}
                        >
                            <div
                                className={`opponentName ${((opponent2 && selectedOpponent === opponent2.id)) ? 'selected' : ''} ${playerTeamId === opponent2?.id ? 'playerTeam' : ''}`}
                                title={opponent2 && opponent2.id !== null && opponent2.id !== CommonConstants.GuidEmpty ? opponent2.name : ''}
                            >
                                {
                                    match.opponent2?.id === CommonConstants.GuidEmpty ? 'BYE' :
                                    match.opponent2?.id === null ? 'TBD' :
                                    opponent2?.tag
                                }
                            </div>
                        </div>
                        
                        <b className={opponent2ResultClassName}>
                            {
                                !match.opponent2 || match.opponent2.id === CommonConstants.GuidEmpty ? '-' :
                                !match.opponent1 || match.opponent1.id === CommonConstants.GuidEmpty ? 'W' :
                                !match.opponent2.score && match.opponent2.result === 'win' ? 'W' :
                                !match.opponent2.score && match.opponent2.score !== 0 && match.opponent2.result === 'loss' ? 'L' :
                                !match.opponent2.score && match.opponent2.score !== 0 && match.opponent2.result === 'draw' ? 'D' :
                                !match.opponent1.score && match.opponent2.score && match.opponent2.result === 'loss' ? 0 :
                                match.opponent2.score ?? '-'
                            }
                        </b>
                    </div>
                </div>
                    {isLineToNextMatch && 
                        <SteppedLineTo 
                            from={`match_${match.id}`} 
                            fromAnchor='right' 
                            to={`match_${match.match_for_winner}`} 
                            toAnchor='left' 
                            isActive={!!selectedOpponent}
                            orientation='h' 
                            borderColor='#7a7a80'
                            borderWidth={2} 
                            className={
                                selectedOpponent &&
                                nextMatch &&
                                ((match.opponent1?.id === selectedOpponent || match.opponent2?.id === selectedOpponent) &&
                                (nextMatch.opponent1?.id === selectedOpponent || nextMatch.opponent2?.id === selectedOpponent))
                                ? `steppedline_selected` : `steppedline`
                            }
                        />}
            </div>
            <div className='match_date' onClick={() => {
                if (isEditing) {
                    setMatchDateEdit(!isMatchDateEdit)
                }
            }}>
                {match.date 
                ? 
                    new Date(match.date).toLocaleDateString('ru-RU', { day: '2-digit', month: 'long' }) 
                : 
                    '-'}{match.date ? ', ' + new Date(match.date).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' }) : ''}
                
            </div>
            
            <div className='match_date'>
                {isMatchDateEdit && (
                    <>
                        <input
                            type="datetime-local"
                            value={editedDate || ''}
                            onChange={(e) => setEditedDate(e.target.value)}
                        />
                        <Button variant="success" onClick={() => handleSaveDate(match.id.toString(), editedDate)}><CheckCircle /></Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default BracketMatch;