export enum MatchesFormatEnum {
    VS1X1 = 0,
    VS2X2 = 1,
    VS3X3 = 2,
    VS5X5 = 3
}

const MatchesFormatEnumMapping: { [key: string]: string } = {
    "VS1X1": '1 на 1',
    "VS2X2": '2 на 2',
    "VS3X3": '3 на 3',
    "VS5X5": '5 на 5',
};

export default MatchesFormatEnumMapping;