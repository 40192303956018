import React from "react";

import './ResultGrid.css';
import { Match, Participant } from "../../../Inerfaces";
import { CommonConstants } from "../../Constants";
import { useNavigate } from "react-router-dom";

interface ResultGridProps {
    participants: Participant[];
    matches: Match[];
    selectedTeam: number | string | null;
}

interface TeamStatInfo {
    id: number | string | null;
    tag: string,
    name: string,
    totalMatches: number,
    totalWins: number,
    totalDraws: number,
    totalLosses: number,
    totalForties: number,
    totalWinMaps: number,
    totalLossMaps: number,
    differenceCardsPlayed: number,
    pts: number,
}

const ResultGrid: React.FC<ResultGridProps> = ({ participants, matches, selectedTeam }) => {
    let teamsStstInfo: TeamStatInfo[] = [];
    
    participants.map((participant) => { 
        let teamId = participant.id;
        
        let participantMatches = matches.filter(match => // Мачи команды
            (match.opponent1?.id === participant.id || match.opponent2?.id === participant.id));
        
        let teamName = participant.name; // Название
        let teamTag = participant.tag;
        let teamTotalMatches = participantMatches.filter(match => match.opponent1.result && match.opponent2.result).length; // Кол-во матчей
        
        let teamTotalWins = participantMatches.filter(match => // Кол-во Побед
            (match.opponent1?.id === participant.id && match.opponent1?.result === 'win') ||
            (match.opponent2?.id === participant.id && match.opponent2?.result === 'win')
        ).length; 
        
        let teamTotalDraws = participantMatches.filter(match => // Кол-во
            (match.opponent1?.id === participant.id && match.opponent1?.result === 'draw') ||
            (match.opponent2?.id === participant.id && match.opponent2?.result === 'draw')
        ).length; 
        
        let teamTotalLosses = participantMatches.filter(match => 
            (match.opponent1?.id === participant.id && match.opponent1?.result === 'loss') ||
            (match.opponent2?.id === participant.id && match.opponent2?.result === 'loss')
        ).length;
        
        let teamTotalForties = () => {
            let countForties: number = 0;
            
            participantMatches.map(match => {
                if (match.opponent1?.id === participant.id) {
                    match.opponent1?.forfeit ? countForties += 1 : countForties += 0;
                } else {
                    match.opponent2?.forfeit ? countForties += 1 : countForties += 0;
                }
            });
            
            return countForties
        } 
        
        let teamTotalWinMaps = () => {
            let countWinMaps: number = 0;
            
            participantMatches.map(match => {
                if (match.opponent1?.id === participant.id) {
                    match.opponent1?.score ? countWinMaps += match.opponent1?.score : countWinMaps += 0;
                } else {
                    match.opponent2?.score ? countWinMaps += match.opponent2?.score : countWinMaps += 0;
                }
            });
            
            return countWinMaps
        }   
        
        let teamTotalLossMaps = () => {
            let countLossMaps = 0;
            
            participantMatches.map(match => {
                
                if (match.opponent1?.id !== participant.id) {
                    match.opponent1?.score ? countLossMaps += match.opponent1?.score : countLossMaps += 0;
                } else {
                    match.opponent2?.score ? countLossMaps += match.opponent2?.score : countLossMaps += 0;
                }
            });
            
            return countLossMaps;
        }
        
        let teamDifferenceCardsPlayed = () => {
            return teamTotalWinMaps() - teamTotalLossMaps();
        }
        
        let teamPts = () => {
            let countPts = 0;
            
            participantMatches.map(match => {
                match.opponent1 && match.opponent1.id === participant.id
                ?
                    match.opponent1.result === 'win' ? countPts += 3 :
                    match.opponent1.result === 'draw' ? countPts += 1 :
                    countPts += 0
                :
                    match.opponent2.result === 'win' ? countPts += 3 :
                    match.opponent2.result === 'draw' ? countPts += 1 :
                    countPts += 0
            });
            
            return countPts;
        }
        
        
        teamsStstInfo.push({
            id: teamId,
            tag: teamTag,
            name: teamName,
            totalMatches: teamTotalMatches,
            totalWins: teamTotalWins,
            totalDraws: teamTotalDraws,
            totalLosses: teamTotalLosses,
            totalForties: teamTotalForties(),
            totalWinMaps: teamTotalWinMaps(),
            totalLossMaps: teamTotalLossMaps(),
            differenceCardsPlayed: teamDifferenceCardsPlayed(),
            pts: teamPts(),
        });
        
    });
    
    return (
        <div className="roundRobin_resultGrid_container">
            <div className="roundRobin_resultGrid_header_container">
                <div className="roundRobin_resultGrid_header" title="Место">#</div>
                <div className="roundRobin_resultGrid_header" title="Название команды">Team</div>
                <div className="roundRobin_resultGrid_header" title="Сыгранные матчи">P</div>
                <div className="roundRobin_resultGrid_header" title="Победы">W</div>
                <div className="roundRobin_resultGrid_header" title="Ничьи">D</div>
                <div className="roundRobin_resultGrid_header" title="Поражения">L</div>
                <div className="roundRobin_resultGrid_header" title="Дисквалификации">F</div>
                <div className="roundRobin_resultGrid_header" title="Выигранные карты">WM</div>
                <div className="roundRobin_resultGrid_header" title="Проигранные карты">LM</div>
                <div className="roundRobin_resultGrid_header" title="Разница победы - поражения">+/-</div>
                <div className="roundRobin_resultGrid_header" title="Очки">Pts</div>
            </div>
            
            <div className="roundRobin_resultGrid_resultContainer">
                {teamsStstInfo.sort((a, b) => b.pts - a.pts).map((team, index) => (
                    <div className="roundRobin_resultGrid_teamContainer" key={index}>
                        <div className="roundRobin_teamStat">{index + 1}</div>
                        <div 
                            className={`roundRobin_teamStat teamName ${team.id === selectedTeam ? 'highlight' : ''}`}
                            title={team.name}
                            onClick={() => window.open(`/team/${team.id}`, '_blank')}
                        >{team.tag}</div>
                        <div className="roundRobin_teamStat">{team.totalMatches}</div>
                        <div className="roundRobin_teamStat">{team.totalWins}</div>
                        <div className="roundRobin_teamStat">{team.totalDraws}</div>
                        <div className="roundRobin_teamStat">{team.totalLosses}</div>
                        <div className="roundRobin_teamStat">{team.totalForties}</div>
                        <div className="roundRobin_teamStat">{team.totalWinMaps}</div>
                        <div className="roundRobin_teamStat">{team.totalLossMaps}</div>
                        <div className="roundRobin_teamStat">{team.differenceCardsPlayed}</div>
                        <div className="roundRobin_teamStat">{team.pts}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ResultGrid;