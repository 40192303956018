import React, { useContext, useState } from 'react';
import './TeamHistory.css';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { AppContext } from '../AppContext';

interface TeamHistoryProps {
    gameTypeId: string;
}

const TeamHistory: React.FC<TeamHistoryProps> = (gameTypeId) => {
    const [historyOpen, setHistoryOpen] = useState(false);
    const { player } = useContext(AppContext);
    const [historyData, setHistoryData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const toggleHistory = () => setHistoryOpen(!historyOpen);

    // useEffect(() => {
    //     if (historyOpen) {
    //         setIsLoading(true);
    //        getPlayerTeamHistory(gameTypeId, player?.playerId)
    //             .then(response => {
    //                 setHistoryData(response.data);
    //                 setIsLoading(false);
    //             })
    //             .catch(error => {
    //                 console.error("Ошибка загрузки истории:", error);
    //                 setIsLoading(false);
    //             });
    //     }
    // }, [historyOpen, gameTypeId, player.playerId]);

    return (
        <div className='team_history_container'>
            <div className='team_history_header' onClick={toggleHistory}>
                <span>История Команд</span>
                {historyOpen ? <CaretUpFill /> : <CaretDownFill />}
            </div>
            {historyOpen && (
                <div className='team_history_content'>
                    {isLoading ? (
                        <p>Загрузка...</p>
                    ) : (
                        historyData.length > 0 ? (
                            <>
                                {historyData.map((item, index) => (
                                    <div key={index}>
                                        Команда
                                    </div>
                                ))}
                            </>
                        ) : (
                            <p>Нет данных по истории участия в командах.</p>
                        )
                    )}
                </div>
            )}
        </div>
    );
};

export default TeamHistory;