import React, { useState } from 'react';
import { IPlayerSelectorProps } from './IPlayerSelectorProps';
import { IPlayerSelectorPlayerModel } from './Models/IPlayerSelectorPlayerModel';
import {  Form } from 'react-bootstrap';
import './PlayerSelector.css';
import { getPlayersByFilters } from '../../http/playerActionsAPI';
import { PlusCircle, Search } from 'react-bootstrap-icons';
import { observer } from 'mobx-react-lite';
import { PlayerSF } from '../../viewModels/filters/PlayerSF';
import { Alert } from 'react-bootstrap';



const PlayerSelector: React.FC<IPlayerSelectorProps> = observer(({ onPlayerSelect }) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState<IPlayerSelectorPlayerModel[] | null>([]);
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const handleInputChange = (e: any) => {
        let value: string = e.target.value as string;
        if (value.length >= 3) { setSearchValue(value); }
    };

    function isErrorWithResponse(error: any): error is { response: { data: any } } {
        return error && error.response && typeof error.response.data === 'string';
    }
    
    const handleSearchSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
    
        const filters: PlayerSF = {
            nickNamePart: searchValue,
            playerWithoutTeam: false,
            showYourself: false
        };
    
        try {
            const results = await getPlayersByFilters(filters);
            if (results === null || results.length === 0) {
                setShowAlert(true);
                setSearchResults([]);
                setErrorMessage('Игроки не найдены');
            } else {
                setSearchResults(results);
                setShowAlert(false);
            }
        } catch (unknownError) {
            console.error('Error fetching players:', unknownError);
            setSearchResults([]);
            setShowAlert(true);
            if (isErrorWithResponse(unknownError)) {
                setErrorMessage(unknownError.response.data);
            } else if (unknownError instanceof Error) {
                setErrorMessage(unknownError.message);
            } else {
                setErrorMessage('Произошла неизвестная ошибка при запросе');
            }
        }
    };
    
    const handleSelectPlayer = (selectedPlayer: IPlayerSelectorPlayerModel) => {
        onPlayerSelect(selectedPlayer);
    };
    
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSearchSubmit(e);
        }
    };
    
    return (
        <div className='search_player_container'>
            {showAlert && (
                <Alert className='search_player_alert' variant="danger" onClose={() => setShowAlert(false)} dismissible>
                    {errorMessage}
                </Alert>
            )}
            <div className='search_player_top'>
                <Form className="search_player_bar">
                    <Form.Control
                        name="search"
                        autoComplete="off"
                        placeholder="ВВЕДИТЕ НИКНЕЙМ"
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        className="rounded-pill"
                    />
                </Form>
                <div>
                    <button type="submit" className='select_player_button' onClick={handleSearchSubmit}><Search /></button>
                </div>
            </div>
                {searchResults !== null && searchResults.length > 0 && ( 
                <div className="players_selecor_block">
                    {searchResults.map((result) => (
                        <div className="searched_players_nickname" key={result.playerId} >
                            <div className='searched_players_nickname_logo'>
                                <img className='searched_players_nickname_logo' src={`${process.env.REACT_APP_IMG_STORAGE_URL}${result.avatarUrl}`} alt="" />
                            </div> 
                            <div className='searched_players_nickname_playername'>
                                <a href={`/player/${result.playerId}`} target='__blank'>
                                    {result.playerName}
                                </a>
                            </div> 
                            <PlusCircle className='searched_players_nickname_plus' onClick={() => handleSelectPlayer(result)} size={27} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
})

export default PlayerSelector;