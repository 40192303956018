import { Container } from 'react-bootstrap';
import './MatchPage.css';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PlayerStatTable from '../../components/PlayerStatTable/PlayerStatTable';
import CustomSpinner1 from '../../components/CustomSpinners/CustomSpinner1';
import { IMatchInfoModel } from '../../models/dto/match/IMatchInfoModel';
import { MatchBracketTypeEnum } from '../../models/enums/tournament/MatchBracketTypeEnum';
import { MatchStatusesEnum } from '../../models/enums/MatchStatusesEnum';
import { FinalStageTypeEnum } from '../../models/enums/tournament/FinalStageTypeEnum';
import { getMatchInfo } from '../../http/tournament/tournamentActionsAPI';

const formatDuration = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
        return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
}

const MatchPage: React.FC = () => {
    const { matchId } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<number>(0);
    const [matchData, setMatchData] = useState<IMatchInfoModel>();

    const [teamAMatchScore, setTeamAMatchScore] = useState<number>(0);
    const [teamBMatchScore, setTeamBMatchScore] = useState<number>(0);

    useEffect(() => {
        if (matchId) {
            getMatchInfo(matchId)
                .then((responce) => {
                    if (responce) {
                        setMatchData(responce);
                    } else {
                        console.log('Ошибка при получение информации о матче');
                    }
                })
                .catch(() => {
                    console.log('Ошибка при получение информации о матче');
                });
        }
    }, [matchId]);

    useEffect(() => {
        let aScore = 0;
        let bScore = 0;

        if (matchData?.cs2Maps?.length && matchData?.cs2Maps?.length > 0) {
            matchData.cs2Maps.forEach((m) => {
                if (m.teamAStat.score > m.teamBStat.score) {
                    aScore += 1;
                } else if (m.teamAStat.score < m.teamBStat.score) {
                    bScore += 1;
                }
            });
        } 

        setTeamAMatchScore(aScore);
        setTeamBMatchScore(bScore);
    }, [matchData]);

    const activeMapStats = matchData?.cs2Maps && matchData.cs2Maps[activeTab];

    const getMatchBOType = (type: number, matchType: string): string => {
        switch (matchType) {
            case 'CommonMatch':
            case 'RobinRound':
            case 'UpperMatch':
            case 'LowerMatch':
                return MatchBracketTypeEnum[type] ? `${MatchBracketTypeEnum[type]}` : '';

            case 'FinalMatch':
            case 'ThirdPlaceMatch':
                return FinalStageTypeEnum[type] ? `${FinalStageTypeEnum[type]}` : '';

            default:
                return '';
        }
    };

    return (
        <Container>
            {matchData ? (
                <>
                    <div className='match_header'>
                        <span onClick={() => navigate(`/tournament/${matchData.tournamentId}`)}>
                            <span>Турнир:</span>{matchData.tournamentName} 
                        </span>
                        
                        {matchData.matchStatus === MatchStatusesEnum.InProgress && (
                            <div>
                                <p>
                                    <div></div>
                                    Live
                                </p>
                            </div>
                        )}
                    </div>
                    <div className='match_layer1'>
                        <div className='match_layer1_team'>
                            <div className='match_layer1_team_header'>КОМАНДА 1</div>
                            <div className='match_layer1_team_name' onClick={() => navigate(`/team/${matchData.teamAId}`)}>
                                {matchData.teamATag}
                            </div>
                        </div>
                        <div className='match_layer1_vs'>
                            <div className='match_layer1_unit'>
                                <img className="tournament_versus_pic" src={`${process.env.REACT_APP_IMG_STORAGE_URL}${matchData.teamALogo}`} alt='' />
                                <div className='match_layer1_count'>{teamAMatchScore}</div>
                            </div>
                            <div className='match_layer1_unit vs'>
                                <div className='tournament_versus_part2 match'>VS</div>
                                <div className='match_layer1_bo'>{getMatchBOType(matchData.boType, matchData.typeOfMatch)}</div>
                            </div>
                            <div className='match_layer1_unit'>
                                <img className="tournament_versus_pic" src={`${process.env.REACT_APP_IMG_STORAGE_URL}${matchData.teamBLogo}`} alt='' />
                                <div className='match_layer1_count'>{teamBMatchScore}</div>
                            </div>
                        </div>
                        <div className='match_layer1_team'>
                            <div className='match_layer1_team_header'>КОМАНДА 2</div>
                            <div className='match_layer1_team_name' onClick={() => navigate(`/team/${matchData.teamBId}`)}>
                                {matchData.teamBTag}
                            </div>
                        </div>
                    </div>
                    <div className='match_layer2'>
                        <div className='match_tabs'>
                            {matchData.cs2Maps ? (
                                matchData.cs2Maps.map((mapStats, index) => (
                                    <button
                                        key={index}
                                        className={`matchtab ${activeTab === index ? 'active' : ''}`}
                                        onClick={() => setActiveTab(index)}
                                    >
                                        КАРТА {index + 1}
                                    </button>
                                ))
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className='matchtab_content'>
                            {activeMapStats ? (
                                <>
                                    <div className='matchtab_content_header'>
                                        <div className='matchtab_content_header_unit'>КАРТА: <span>{activeMapStats.mapName}</span></div>
                                        <div className='matchtab_content_header_unit two'><span>{formatDuration(activeMapStats.durationInSeconds)}</span></div>
                                    </div>
                                    <div className='matchtab_content_layer'>
                                        <div className='matchtab_content_layer_team'>
                                            <div className='matchtab_content_team'>
                                                <div className='matchtab_content_team_name' onClick={() => navigate(`/team/${activeMapStats.teamAStat.teamId}`)}>
                                                    {activeMapStats.teamAStat.name}
                                                </div>
                                                <div className={`matchtab_content_team_status ${activeMapStats.teamAStat.score > activeMapStats.teamBStat.score ? '' : (activeMapStats.teamAStat.score < activeMapStats.teamBStat.score ? 'loss' : 'draw')}`}>
                                                    {activeMapStats.teamAStat.score > activeMapStats.teamBStat.score
                                                        ? 'ПОБЕДА'
                                                        : (activeMapStats.teamAStat.score < activeMapStats.teamBStat.score
                                                            ? 'ПОРАЖЕНИЕ'
                                                            : 'НИЧЬЯ')}
                                                </div>
                                                <div className='matchtab_content_team_count'>
                                                    {activeMapStats.teamAStat.score}
                                                </div>
                                            </div>
                                            <div className='matchtab_table'>
                                                <PlayerStatTable data={activeMapStats.teamAStat.rows} isMatchPage={true} />
                                            </div>
                                        </div>
                                        <div className='matchtab_content_layer_team'>
                                            <div className='matchtab_content_team'>
                                                <div className='matchtab_content_team_name' onClick={() => navigate(`/team/${activeMapStats.teamBStat.teamId}`)}>
                                                    {activeMapStats.teamBStat.name}
                                                </div>
                                                <div className={`matchtab_content_team_status ${activeMapStats.teamBStat.score > activeMapStats.teamAStat.score ? '' : (activeMapStats.teamBStat.score < activeMapStats.teamAStat.score ? 'loss' : 'draw')}`}>
                                                    {activeMapStats.teamBStat.score > activeMapStats.teamAStat.score
                                                        ? 'ПОБЕДА'
                                                        : (activeMapStats.teamBStat.score < activeMapStats.teamAStat.score
                                                            ? 'ПОРАЖЕНИЕ'
                                                            : 'НИЧЬЯ')}
                                                </div>
                                                <div className='matchtab_content_team_count'>
                                                    {activeMapStats.teamBStat.score}
                                                </div>
                                            </div>
                                            <div className='matchtab_table'>
                                                <PlayerStatTable data={activeMapStats.teamBStat.rows} isMatchPage={true} />
                                            </div>
                                        </div>
                                    </div>
                                    {matchData.streamUrl && (
                                        <div className='matchstream_button'>
                                            <button className='matchtab' onClick={() => window.open(matchData.streamUrl, "_blank")}>
                                                СМОТРЕТЬ ТРАНСЛЯЦИЮ
                                            </button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div>
                                    {matchData.streamUrl && (
                                        <div className='matchstream_button only'>
                                            <button className='matchtab' onClick={() => window.open(matchData.streamUrl, "_blank")}>
                                                СМОТРЕТЬ ТРАНСЛЯЦИЮ
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <CustomSpinner1 />
            )}
        </Container>
    );
};

export default MatchPage;
